import React from "react";
import styled from "styled-components";

const StyledHeadContainer = styled.div`
  color: ${({ theme }) => theme.fg};
  padding: 2rem 1.5rem;
  ${({ hasBorderBottom }) =>
    hasBorderBottom && `border-bottom: 0.2rem solid #cfd8dc;`}
`;
function HeadContainer({ children, ...props }) {
  return (
    <StyledHeadContainer className="hero-head" {...props}>
      {children}
    </StyledHeadContainer>
  );
}

export default HeadContainer;

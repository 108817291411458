import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import themes from "../../libs/themes";
import nativeChannel from "../../nativeChannel";
import Button from "../Button";

import OnScreenKeyboard from "../OnScreenKeyboard";

function TextField({
  placeholder,
  onChange,
  defaultValue,
  isScannable = true,
  disabled,
  cy,
  autofocus = false,
  isError = false,
}) {
  const inputEl = useRef(null);
  const [showKeyboard, setShowKeyboard] = useState(false);

  useEffect(() => {
    if (autofocus) {
      inputEl && inputEl.current && inputEl.current.select();
    }
    if (isScannable) {
      window.onBarcode = onBarcode;
      nativeChannel.on("barcode", onBarcode);
      return () => {
        window.onBarcode = null;
        nativeChannel.off("barcode", onBarcode);
      };
    }
  }, [inputEl]);

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { BARCODE_SCANNER } = hmiConfiguration;

  function onBarcodeSearch() {
    nativeChannel.send("read_barcode");
  }

  async function onBarcode(barcode) {
    if (inputEl.current) inputEl.current.value = barcode;
    onChange(barcode);
  }

  return (
    <>
      <div className="columns">
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <div className="separator" />
          <input
            name="text-field"
            id="text-field"
            data-cy={cy ?? "application-text-input"}
            type="text"
            className="title is-1 is-double"
            placeholder={placeholder ?? ""}
            defaultValue={defaultValue ?? ""}
            onFocus={() => {
              if (inputEl.current) {
                inputEl.current.select();
              }
              setShowKeyboard(true);
            }}
            onInput={(event) => onChange(event.target.value)}
            ref={inputEl}
            onBlur={() => {
              setShowKeyboard(false);
            }}
            disabled={disabled}
            style={{
              color: "#455a64",
              width: "100%",
              border: "none",
              outline: "none",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
          {BARCODE_SCANNER && isScannable && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={onBarcodeSearch}
              data-cy="application-button-scan"
              style={{
                position: "absolute",
                right: 0,
                width: "27rem",
                height: "7.5rem",
                transform: "translate(-48px, 8px)",
              }}
            >
              <i className="icon-barcode" style={{ fontSize: "2.4rem" }} />
              <p className="title is-4">Scan barcode</p>
            </Button>
          )}
        </div>
      </div>
      {showKeyboard && !disabled && (
        <OnScreenKeyboard inputNode={inputEl.current} />
      )}
    </>
  );
}

TextField.propTypes = {};

export default TextField;

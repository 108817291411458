import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { loader } from "graphql.macro";

import { setFlow, setMachine, setProgram, setStation } from "../../../actions";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import Operation from "./components/Operation";
import onError from "../../../libs/error-logger";
import { hasFeature } from "../../../components/HasFeature";

const PHASES = loader("./Dashboard.query.graphql");
const RELEASE = loader("./Release.mutation.graphql");
const TAKE = loader("./Take.mutation.graphql");

function Dashboard() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const { features, hmiConfiguration } = useSelector(({ config }) => config);
  const { STATION_ID } = hmiConfiguration;
  const { data, loading, error, refetch } = useQuery(PHASES, {
    variables: {
      populate: [
        { path: "job", populate: { path: "product" } },
        { path: "product" },
      ],
      workerId: user.worker._id,
    },
  });

  //selected phases ids of the list
  const [selectedIndexes] = React.useState([]);

  const [takeMutate] = useMutation(TAKE);
  const [releaseMutate] = useMutation(RELEASE);

  //is the list item selected
  const isSelected = (index) => selectedIndexes.includes(index);
  function onClick(phase) {
    const {
      _id,
      _program,
      _machine,
      job,
      _station,
      configuration,
      activeSession: session,
      activeSessions,
    } = phase;
    // FeatureFlag: Multi user sessions
    if (!hasFeature("MULTI_USER", features)) {
      if (!session && activeSessions.length > 0) {
        onError(new Error("Multi user sessions not enabled"));
        return;
      }
    }
    dispatch(setStation(_station));
    dispatch(setProgram(_program));
    dispatch(setMachine(_machine));
    dispatch(setFlow("OPERATOR"));

    if (!session) {
      return history.push(`/configuration/definition/${job._id}/${_id}`);
    }

    if (configuration.HMI_MODE === "WORKFLOW") {
      // If new status is pause change HMI path to PAUSE
      switch (session.status) {
        case "PAUSE":
          return history.push(
            `/application/pause/${_id}/${session._id}/${session._step || ""}`
          );
        case "PROBLEM":
          return history.push(
            `/application/problem/${_id}/${session._id}/${session._step || ""}`
          );
        default:
          return history.push(
            `/application/step/${_id}/${session._id}/${session._step || ""}`
          );
      }
    }
    if (configuration.HMI_MODE === "FREE") {
      switch (session.status) {
        case "MATERIAL":
          return history.push(`/application/material/${_id}/${session._id}`);
        case "SETUP":
          return history.push(`/application/setup/${_id}/${session._id}`);
        case "APPROVAL":
          return history.push(`/application/approval/${_id}/${session._id}`);
        case "PRODUCTION":
          return history.push(`/application/production/${_id}/${session._id}`);
        case "CHECK":
          return history.push(`/application/check/${_id}/${session._id}`);
        case "PAUSE":
        default:
          return history.push(`/application/menu/${_id}/${session._id}`);
      }
    }
  }

  async function onTake({ phaseId }) {
    const variables = { phaseId };
    try {
      await takeMutate({ variables });
    } catch (error) {
      console.log(error);
    }
  }

  async function onRelease({ phaseId }) {
    const variables = { phaseId };
    try {
      await releaseMutate({ variables });
      refetch();
    } catch (error) {
      console.log(error);
    }
  }

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  // Filtro fasi temporaneamente per far aggiungere alla coda quelle delle stazioni
  const phases = data.phases;
  const taken = phases.filter(
    (p) =>
      p._tookByWorkers != null && p._tookByWorkers.includes(user.worker._id)
  );

  const queue = phases.filter(
    (p) =>
      p._tookByWorkers == null || !p._tookByWorkers.includes(user.worker._id)
  );

  const workerQueue = queue.filter((p) => p._worker != null);

  const workgroupQueue = queue.filter(
    (p) => p._worker == null && p._workgroup != null
  );

  const stationQueue = queue.filter(
    (p) =>
      STATION_ID &&
      p._station == STATION_ID &&
      !(p._worker == null && p._workgroup != null) &&
      p._worker == null
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Operations - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasUserInfo hasSessionInfo={false} />}>
          <>
            {/* DATA */}
            <div className="columns">
              <div className="column is-4" style={{ paddingTop: "2rem" }}>
                <h2 className="title is-2 w700">
                  <FormattedMessage
                    id="app.pages.configuration.operations.takenover"
                    defaultMessage="Add"
                  />
                </h2>
                <p className="subtitle is-6 fg-secondary">
                  <FormattedMessage
                    id="app.pages.configuration.operations.takenovermessage"
                    defaultMessage="Add"
                  />
                </p>
              </div>
              <div className="column is-2"></div>
              <div className="column" style={{ textAlign: "right" }}>
                <Button
                  className="button"
                  theme={themes.primary.buttons}
                  onClick={() => {
                    dispatch(setFlow("DASHBOARD"));
                    history.push(`/configuration/add-operations`);
                  }}
                  style={{ transform: "translateX(-1rem)" }}
                  data-cy="application-add-operation"
                >
                  <FormattedMessage
                    id="app.pages.configuration.operations.add"
                    defaultMessage="Add"
                  />
                </Button>
                <Button
                  className="button"
                  onClick={() => history.push(`/worker/timesheet`)}
                  data-cy="application-timesheet"
                >
                  <FormattedMessage
                    id="app.pages.configuration.operations.timesheet"
                    defaultMessage="Timesheet"
                  />
                </Button>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                {taken.map((phase) => (
                  <Operation
                    key={phase._id}
                    phase={phase}
                    onRelease={onRelease}
                    isTaken={true}
                    onClick={onClick}
                  />
                ))}

                {taken.length === 0 && (
                  <div className="subtitle is-3">
                    <FormattedMessage
                      id="app.pages.configuration.operations.no_phase_taken"
                      defaultMessage="There is no phase"
                    />
                  </div>
                )}
              </div>
            </div>

            {workerQueue.length > 0 && (
              <>
                <hr />
                <br />
                <div className="columns">
                  <div className="column is-3">
                    <h2 className="title is-2 w700">
                      <FormattedMessage
                        id="app.pages.configuration.operations.operator_queue"
                        defaultMessage="Queue"
                      />
                    </h2>
                    <p className="subtitle is-6 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.queuemessage"
                        defaultMessage="Your queue"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    {workerQueue.map((phase) => (
                      <Operation
                        key={phase._id}
                        phase={phase}
                        onTake={() => {
                          onTake({ phaseId: phase._id });
                        }}
                        onClick={onClick}
                        onSelect={() => {}}
                        selected={isSelected(phase._id)}
                      />
                    ))}

                    {workerQueue.length === 0 && (
                      <div className="subtitle is-3">
                        <FormattedMessage
                          id="app.pages.configuration.operations.no_phase"
                          defaultMessage="There is no phase, contact responsible"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {workgroupQueue?.length > 0 && (
              <>
                <hr />
                <br />
                <div className="columns">
                  <div className="column is-5">
                    <h2 className="title is-2 w700">
                      <FormattedMessage
                        id="app.pages.configuration.operations.team_queue"
                        defaultMessage="Queue"
                      />
                    </h2>
                    <p className="subtitle is-6 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.queuemessage"
                        defaultMessage="Your queue"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    {workgroupQueue.map((phase) => (
                      <Operation
                        key={phase._id}
                        phase={phase}
                        onTake={() => {
                          onTake({ phaseId: phase._id });
                        }}
                        onClick={onClick}
                        onSelect={() => {}}
                        selected={isSelected(phase._id)}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            {stationQueue?.length > 0 && (
              <>
                <hr />
                <br />
                <div className="columns">
                  <div className="column is-5">
                    <h2 className="title is-2 w700">
                      <FormattedMessage
                        id="app.pages.configuration.operations.station_queue"
                        defaultMessage="Queue"
                      />
                    </h2>
                    <p className="subtitle is-6 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.queuemessage"
                        defaultMessage="Your queue"
                      />
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    {stationQueue?.map((phase) => (
                      <Operation
                        key={phase._id}
                        phase={phase}
                        onTake={() => {
                          onTake({ phaseId: phase._id });
                        }}
                        onClick={onClick}
                        onSelect={() => {}}
                        selected={isSelected(phase._id)}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className="columns">
              <div className="column is-12">
                {queue?.length === 0 && (
                  <div className="subtitle is-3">
                    <FormattedMessage
                      id="app.pages.configuration.operations.no_phase"
                      defaultMessage="There is no phase, contact responsible"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default Dashboard;

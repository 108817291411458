import React from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

function ListBadge({
  children,
  selectable = false,
  selected = false,
  withCircle = true,
  onSelect,
  theme,
  icon,
  ...props
}) {
  return (
    <>
      <Badge>{children}</Badge>
    </>
  );
}

ListBadge.propTypes = {};
export default compose(withTheme)(ListBadge);

import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { ThemeProvider } from "styled-components";

import Button from "../../../components/Button";
import Title from "../../../components/Title";
import Subtitle from "../../../components/Subtitle";
import { setUser } from "../../../actions";
import Header from "../../../components/Header";
import Clock from "../../../components/Clock";
import OperatorsGrouped from "./components/OperatorsGrouped";

import onError from "../../../libs/error-logger";
import Background from "../../../components/Layout";
import themes from "../../../libs/themes";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import ScanWorkId from "./components/ScanWorkId";
import { loader } from "graphql.macro";
const OPERATORS = loader("./ChooseOperator.query.graphql");
const CREATE_TOKEN = loader("./ChooseOperator.mutation.graphql");

/**
 * ChooseOperator
 *
 * @returns {*}
 * @constructor
 */
function ChooseOperator() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { index } = useParams();
  const { stationId, hmiConfiguration, flow } = useSelector(
    ({ config }) => config
  );
  const { STATION_ID, WORKERS_PAGE_SIZE } = hmiConfiguration;

  const [mutate] = useMutation(CREATE_TOKEN);
  const { data, loading, error } = useQuery(OPERATORS, {
    variables: { stationId },
  });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { operators } = data;

  async function onClick({ workerId, code }) {
    const { CHOOSE_PHASE, STAMP_AFTER_LOGIN } = hmiConfiguration;
    const variables = { workerId, code };
    try {
      const result = await mutate({ variables });
      const { worker } = result.data;
      dispatch(setUser(worker));
      if (flow === "OPERATOR") {
        if (STAMP_AFTER_LOGIN) {
          return history.push("/badge");
        } else {
          return history.push("/worker/dashboard");
        }
      }
      if (flow === "ORDER") {
        return history.push(`/configuration/operation/${state?.jobId}`);
      }
      if (CHOOSE_PHASE) {
        return history.push("/configuration/order");
      }
      return history.push("/configuration/next-phase");
    } catch (error) {
      onError(error);
    }
  }

  function onBack() {
    if (index) {
      return history.push("/configuration/operator");
    }
    if (flow === "OPERATOR") {
      return history.push("/");
    }
    if (flow === "ORDER") {
      return history.push(`/configuration/operation/${state?.jobId}`);
    }
    if (STATION_ID) {
      return history.push("/");
    }
    return history.push("/configuration/station");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth theme={theme.buttons} onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <ScanWorkId onSubmit={onClick} />
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.operators.title"
                    defaultMessage="Choose user"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.operators.subtitle"
                    defaultMessage="Tell me who you are"
                  />
                </Subtitle.S3>
              </div>
            </div>

            <OperatorsGrouped
              onClick={onClick}
              operators={operators}
              pageSize={WORKERS_PAGE_SIZE}
              jobId={state?.jobId}
            />
            {operators.length === 0 && (
              <Title.H2>
                <FormattedMessage
                  id="app.pages.configuration.operators.empty"
                  defaultMessage="Sorry, no operator assigned to this station, contact a responsible"
                />
              </Title.H2>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default ChooseOperator;

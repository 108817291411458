import classNames from "classnames";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import PropTypes from "prop-types";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components";
import { useSelector } from "react-redux";

import themes from "../../../../libs/themes";
import ListElement from "../../../../components/ListElement";
import SessionBadge from "../../../../components/SessionBadge";

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 * Job
 *
 * @param job
 * @param phases
 * @param history
 * @param user
 * @returns {*}
 * @constructor
 */
function Job({ job, phases, history, user }) {
  const isLate =
    job.deadline && isBefore(new Date(job.deadline), startOfDay(new Date()));
  const {
    hmiConfiguration: { WELCOME_CHOOSE_ORDER },
  } = useSelector(({ config }) => config);
  const total = WELCOME_CHOOSE_ORDER ? job.phasesCount : job.phases.length;

  const handleClick = () => {
    if (user?.worker) {
      return history.push(`/configuration/operation/${job._id}`);
    } else {
      return history.push("/configuration/operator", { jobId: job._id });
    }
  };

  return (
    <ListElement
      onClick={handleClick}
      data-cy="configuration-orders-order"
      data-cy-id={job._id}
      icon={job.product && job.product.image}
      isAvailable={job.available}
      theme={
        job.available ? themes.default.listElement : themes.welcome.listElement
      }
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column">
          <p className="subtitle is-6">
            {phases && phases.length > 0 && phases[0].session && (
              <SessionBadge
                isSmall={false}
                sessions={phases[0].activeSessions}
              />
            )}
            {job.product && (
              <Badge>
                <i className="icon-product" />
                {job.product.code}
              </Badge>
            )}
            {job.customer && (
              <Badge>
                <i className="icon-st-users-icn" />
                {job.customer.name}
              </Badge>
            )}
            {job.salesOrderId && (
              <Badge>
                <i className="icon-doc-text" />
                {job.salesOrderId}
              </Badge>
            )}
          </p>
          <p className={`title is-3 ${!job.available && "has-text-grey"}`}>
            {job.name}
          </p>
        </div>

        {job.deadline && (
          <div className="column is-narrow fg-secondary">
            <small className={classNames(isLate && "is-late")}>
              <FormattedDate
                value={job.deadline}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </small>
          </div>
        )}
      </div>

      {total > 0 && (
        <div className={`column ${!job.available && "has-text-grey"}`}>
          <FormattedMessage
            id="app.shared.operation_assigned.short"
            defaultMessage="{total, plural, =0 {No operation} one {{total, number} operation} other {{total, number} operations}}"
            values={{ total }}
          />
        </div>
      )}
    </ListElement>
  );
}

Job.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    available: PropTypes.bool.isRequired,
    salesOrderId: PropTypes.string,
    batch: PropTypes.string,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    meta: PropTypes.shape({
      customerCode: PropTypes.string,
    }),
    customer: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    phases: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ),
  }),

  user: {
    worker: {
      id: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
    },
  },
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      __typename: PropTypes.string.isRequired,
      activeSessions: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          _step: PropTypes.string.isRequired,
          __typename: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          worker: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            __typename: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
          }),
        })
      ),
    })
  ),
};
export default compose(withRouter)(Job);

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";
import Button from "./Button";
import ListElemModal from "./ListElemModal";

const SIZE = 24;

const StyledListElement = styled(Button)`
  text-align: left !important;
  margin-bottom: 0.5rem;
  justify-content: initial;
  padding: 2.2rem ${({ notes }) => (notes ? "4.2rem" : "2.2rem")} 2.2rem
    ${({ withCircle }) => (withCircle ? "8.8" : "2.2")}rem;
  font-size: 3rem;

  ${({ selected }) =>
    selected ? "box-shadow: 0 0 0 0.4rem rgba(0,0,0,0.2) inset; " : ""}
  .column {
    &:first-child {
      overflow: hidden;

      .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .product-image {
    width: 4.4rem;
    height: 4.4rem;
    position: absolute;
    left: 2.2rem;
    top: 2.2rem;
    border-radius: 0.4rem;
  }

  .leading {
    width: 4.8rem;
    height: 4.8rem;
    position: absolute;
    left: 2.2rem;
    top: 2rem;
    border-radius: 2.4rem;
    background-color: rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 2.2rem;
    }
  }

  .trailing {
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    right: 1.4rem;
    top: 1.6rem;
    color: rgba(0, 0, 0, 0.3);
  }

  .subtitle {
    margin-bottom: 1.2rem;
    margin-top: 0.4rem;
  }

  .notes {
    position: absolute;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 2.4rem;
    width: 100%;
    bottom: 0;
    left: 0;
    font-size: 2rem;
    font-weight: 400;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.04);

    div {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 600;
      margin-right: 2.8rem;
    }
  }

  &.notes {
    height: 12.2rem;
    padding-bottom: 6.2rem;
    overflow: hidden;
  }
`;

const CircleOutline = styled.div`
  border: 4px solid ${({ theme }) => theme.circle || "#fff"};
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 2.2rem;
  position: absolute;
  left: 2.2rem;
  top: 2.2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

function ListElement({
  children,
  selectable = false,
  selected = false,
  withCircle = true,
  hasTrailing = false,
  isContainer = false,
  isNew = false,
  onSelect,
  theme,
  icon,
  index,
  notes,
  isAvailable = true,
  ...props
}) {
  return (
    <StyledListElement
      className={"icon " + (notes && "notes")}
      isFullWidth
      withCircle={withCircle}
      theme={theme}
      {...props}
    >
      <ListElemModal
        className={"icon"}
        head={
          <p className="title is-4 w500 has-text-centered has-text-white">
            Prodotto
          </p>
        }
        button={
          withCircle && (
            <>
              {selectable && !selected ? (
                <div onClick={onSelect}>
                  <CircleOutline theme={theme} />
                </div>
              ) : (
                <div onClick={selectable ? onSelect : () => {}}>
                  {!isAvailable ? (
                    <div className="leading">
                      <i className="icon-giphy-lock" />
                    </div>
                  ) : icon ? (
                    <img
                      src={icon}
                      alt="logo"
                      width={SIZE}
                      height={SIZE}
                      className="product-image"
                    />
                  ) : index != null ? (
                    <div
                      className="leading title is-5"
                      style={{ pointerEvents: "none" }}
                    >
                      {index}
                    </div>
                  ) : (
                    <div className="leading" style={{ pointerEvents: "none" }}>
                      <i
                        className={
                          isNew
                            ? "icon-arrow-plus-small-icn"
                            : isContainer
                            ? "icon-sc-containers-icn"
                            : "icon-product"
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )
        }
      >
        {selected}
        {icon ? (
          <img
            src={icon}
            alt=""
            className="image"
            style={{
              margin: "auto",
              width: "100%",
              maxWidth: "400px",
              height: "auto",
            }}
          />
        ) : (
          <i
            className={
              isNew
                ? "icon-arrow-plus-small-icn"
                : isContainer
                ? "icon-sc-containers-icn"
                : "icon-product"
            }
            style={{ fontSize: "8.2rem" }}
          />
        )}
      </ListElemModal>

      <>
        <>{children}</>
        {hasTrailing && (
          <div className="trailing">
            <i
              className="icon-arrow-right-small-icn"
              style={{ fontSize: "1.6rem" }}
            ></i>
          </div>
        )}
        {notes && (
          <>
            <div className="notes">
              <div>Notes</div>
              {notes}
            </div>
          </>
        )}
      </>
    </StyledListElement>
  );
}

ListElement.propTypes = {
  children: PropTypes.node,
  isAvailable: PropTypes.bool,
};
export default compose(withTheme)(ListElement);

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import themes from "../../libs/themes";
import nativeChannel from "../../nativeChannel";
import Button from "../Button";

import OnScreenKeyboard from "../OnScreenKeyboard";

function SearchField({
  placeholder,
  onChange,
  defaultValue,
  isScannable = true,
  disabled,
  cy,
  autofocus = false,
  isError = false,
}) {
  const inputEl = useRef(null);
  const [showKeyboard, setShowKeyboard] = useState(false);

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { BARCODE_SCANNER } = hmiConfiguration;

  useEffect(() => {
    if (autofocus) {
      inputEl && inputEl.current && inputEl.current.select();
    }
    if (isScannable) {
      window.onBarcode = onBarcode;
      nativeChannel.on("barcode", onBarcode);
      return () => {
        window.onBarcode = null;
        nativeChannel.off("barcode", onBarcode);
      };
    }
  }, [inputEl]);

  function onBarcodeSearch() {
    nativeChannel.send("read_barcode");
  }

  async function onBarcode(barcode) {
    if (inputEl.current) inputEl.current.value = barcode;
    onChange(barcode);
  }

  return (
    <>
      <div className="columns" style={{ position: "relative" }}>
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <input
            name="text-field"
            id="text-field"
            data-cy={cy ?? "application-text-input"}
            type="text"
            className="input is-rounded title is-4 is-double"
            placeholder={placeholder ?? ""}
            defaultValue={defaultValue ?? ""}
            onBlur={() => {
              setShowKeyboard(false);
            }}
            onFocus={() => {
              if (inputEl.current) {
                inputEl.current.select();
              }
              setShowKeyboard(true);
            }}
            onInput={(event) => onChange(event.target.value)}
            //onInput={(event) => console.log(event.target.value)}
            //onChange={(event) => onChange(event.target.value)}
            ref={inputEl}
            disabled={disabled}
          />
          {BARCODE_SCANNER && isScannable && defaultValue == "" && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={onBarcodeSearch}
              data-cy="application-button-scan"
              style={{
                borderRadius: "4rem",
                position: "absolute",
                right: 0,
                width: "25.2rem",
                height: "6.7rem",
                transform: "translate(-10px, 3px)",
              }}
            >
              <i className="icon-barcode" style={{ fontSize: "2.4rem" }} />
              <p className="title is-4">Scan barcode</p>
            </Button>
          )}
          {defaultValue != "" && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={() => {
                inputEl.current.value = "";
                onChange("");
              }}
              data-cy="application-button-close"
              style={{
                borderRadius: "4rem",
                position: "absolute",
                right: 0,
                width: "6.7rem",
                height: "6.7rem",
                transform: "translate(-10px, 3px)",
              }}
            >
              <i
                className="icon-arrow-close-small-icn"
                style={{ fontSize: "1.8rem" }}
              />
            </Button>
          )}
        </div>
      </div>
      {showKeyboard && !disabled && (
        <OnScreenKeyboard inputNode={inputEl.current} />
      )}
    </>
  );
}

SearchField.propTypes = {};

export default SearchField;

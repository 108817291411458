import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: relative;
  &.disabled {
  }
  .image {
    margin-bottom: 0.8rem;
    width: 13rem !important;
    height: 13rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
  }
`;

function AddContainer({ onClick }) {
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <a
        onClick={onClick}
        data-cy="application-containers-container"
        data-cy-id="add-container"
      >
        <figure className="image">
          <div style={{ fontSize: "7.2rem", transform: "translateY(-1.8rem)" }}>
            ...
          </div>
        </figure>
        <h4 className="title is-4 w500 has-text-centered">All containers</h4>
      </a>
    </StyledWrapper>
  );
}

AddContainer.propTypes = {
  onClick: PropTypes.string.isRequired,
};
export default AddContainer;

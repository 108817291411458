import React from "react";
import PropTypes from "prop-types";

import ListElement from "../../../../../components/ListElement";
import themes from "../../../../../libs/themes";
import { darken } from "polished";

const selectedTheme = {
  ...themes.default.listElement,
  bg: darken(0.05, themes.default.listElement.bg),
};

/**
 * Item
 *
 * @param item
 * @param selected - selected item
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
function Item({ item, selected, onClick }) {
  const isSelected = item._id === selected;
  return (
    <ListElement
      onClick={() => onClick(item._id)}
      theme={isSelected ? selectedTheme : themes.default.listElement}
      data-cy="application-container_item-item"
      data-cy-id={item._id}
      className={isSelected ? "selected" : ""}
      icon={item.image}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column is-narrow">
          <ul>
            <li className="title is-4">{item.code}</li>
            <li className="subtitle is-4 fg-secondary is-marginless-bottom">
              {item.name}
            </li>
          </ul>
        </div>
      </div>
    </ListElement>
  );
}

Item.propTypes = {
  selected: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default Item;

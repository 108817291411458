import styled from "styled-components";

export const InputsWrap = styled("div")`
  display: grid;
  gap: 8px;
  margin-bottom: 1.5rem;
  grid-template-columns: ${({ hasSecondUnit }) =>
    hasSecondUnit ? "4fr 1fr" : "1fr"};

  span {
    display: none;
  }
`;

export const ButtonRemove = styled("button")`
  background: #ff0000;
  display: flex;
  align-items: center;
  margin-top: 16.5px;
  gap: 8px;
  color: #ffffff;
  height: 50px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 12.5px 14px;
  font-weight: 500;
  font-size: 24px;
  max-width: 149px;

  &:hover {
    opacity: 0.9;
  }
`;

export const Button = styled("button")`
  background: #495963;
  display: flex;
  align-items: center;
  margin-top: 16.5px;
  gap: 8px;
  color: #ffffff;
  height: 50px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 12.5px 18.5px;
  font-weight: 500;
  font-size: 24px;

  &:hover {
    opacity: 0.9;
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import Button from "../../../../components/Button";
import themes, { statuses } from "../../../../libs/themes";

/**
 * Next Step Button
 *
 * @param steps
 * @param history
 * @returns {*}
 * @constructor
 */
function NextStepButton({ steps, disabled = false }) {
  const intl = useIntl();
  const { phaseId, sessionId, stepId } = useParams();
  const history = useHistory();
  const currentIndex = steps.findIndex(({ _id }) => _id === stepId);
  if (currentIndex + 1 < steps.length) {
    const step = steps[currentIndex + 1];
    return (
      <Button
        isFullWidth
        theme={statuses[step.status]?.buttons || themes.default.buttons}
        onClick={() =>
          history.push(`/application/step/${phaseId}/${sessionId}/${step._id}`)
        }
        data-cy="application-button-next"
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        {step.name ??
          intl.formatMessage({
            id: `app.shared.${step.status.toLowerCase()}`,
            defaultMessage: step.status,
          })}{" "}
        <i className="icon-arrow-right-small-icn" />
      </Button>
    );
  }
  return <span />;
}

NextStepButton.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NextStepButton;

import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { useMutation, useQuery } from "@apollo/client";
import { resetPhase, setProduced, setSecondProduced } from "../../../actions";
import { MediumButton } from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout/Background";
import LoadingIndicator from "../../../components/LoadingIndicator";
import StepHeader from "../../../components/StepHeader";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import confirmAction from "../../../libs/confirm_action";
import themes, { buttons } from "../../../libs/themes";
import toast from "../../../libs/toast";
import GenericError from "../../errors/GenericError";
import { ButtonRemove, Button, InputsWrap } from "./style";
import { ReactComponent as IconRemove } from "../../../images/remove.svg";
import { ReactComponent as IconScales } from "../../../images/scales.svg";

import { loader } from "graphql.macro";
import NumberField from "../../../components/Fields/NumberField";
import Subtitle from "../../../components/Subtitle";
const PRODUCED_QUERY = loader("./Produced.query.graphql");
const SET_PRODUCED = loader("./Produced.mutation.graphql");

function Produced({ intl }) {
  const [secondUnit, setSecondUnit] = useState(false);
  const [convFactor, setConvVactor] = useState("-");
  const firstInputRef = useRef();
  const secondInputRef = useRef();
  const [isError, setIsError] = useState(false);
  const { phaseId, sessionId } = useParams();
  const [currentSession] = useState(sessionId);
  const { hmiConfiguration } = useSelector(({ config }) => config);

  const delivery = useSelector(({ delivery }) => delivery);

  const dispatch = useDispatch();
  const history = useHistory();

  const variables = { sessionId: currentSession };

  const [mutate] = useMutation(SET_PRODUCED);

  const { loading, error, data } = useQuery(PRODUCED_QUERY, { variables });

  const theme = themes.default;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { session } = data;
  const { phase } = session;

  function showError(message) {
    setIsError(true);
    toast({ title: intl.formatMessage({ id: message }), icon: "error" });
    setTimeout(() => setIsError(false), 800);
  }

  const getConvFactor = () => {
    const factor = Number(
      (firstInputRef.current?.value / secondInputRef.current?.value).toFixed(2)
    );

    return isNaN(factor) || factor === Infinity || typeof factor !== "number"
      ? null
      : factor;
  };

  function onBack() {
    if (delivery.status === "ERROR") {
      dispatch(setProduced());
      dispatch(setSecondProduced());
    } else {
      dispatch(resetPhase());
    }
    return history.goBack();
  }

  async function onSubmit() {
    const { DELIVER_MORE_THAN_QTY } = phase.configuration;
    const produced =
      Number(firstInputRef.current?.value) ??
      delivery.session.produced ??
      session.produced ??
      0;
    const secondProduced = isNaN(secondInputRef.current?.value)
      ? 0
      : Number(secondInputRef.current?.value) ?? 0;

    if (isNaN(produced)) {
      showError("app.shared.nan");
      return;
    }

    const total = +phase.produced - +(session.produced || 0) + +produced;
    // If HMI DELIVER_MORE_THAN_QTY is true and delivering more than QTY block user
    if (
      DELIVER_MORE_THAN_QTY === false &&
      total > +phase.qty &&
      +phase.qty > 0
    ) {
      return toast({
        title: intl.formatMessage(
          {
            id: "app.pages.application.produced.error.greater_than_qty",
            defaultMessage:
              "Attention, total production is greater than quantity. Production: {total, number}/{qty, number}",
          },
          { total, qty: phase.qty }
        ),
        icon: "error",
      });
    }
    // If operation has dependencies, check if they have DEPENDENCIES_DELIVER_MORE_THAN_QTY false
    if (phase.dependencies.length > 0) {
      const check = phase.dependencies.filter(
        (p) =>
          p.phase.configuration.DEPENDENCIES_DELIVER_MORE_THAN_QTY === false
      );
      const [min] = check.map((d) => d.phase.produced).sort();
      if (min && total > min) {
        return toast({
          title: intl.formatMessage(
            {
              id: "app.pages.application.produced.error.greater_than_dependencies",
              defaultMessage:
                "Attention, total production is greater than previous delivered quantity. Production: {total, number}/{min, number}",
            },
            { total, min }
          ),
          icon: "error",
        });
      }
    }
    // If final delivery and TOTAL < QTY and QTY > 0 displays a warning
    if (delivery.status === "DONE" && total < +phase.qty && +phase.qty > 0) {
      return confirmAction({
        async confirm() {
          dispatch(setProduced(produced));
          dispatch(setSecondProduced(secondProduced));
          await saveAndNext(produced, secondProduced);
        },
        options: {
          text: intl.formatMessage(
            {
              id: "app.pages.application.produced.confirm.less_than_qty",
              defaultMessage:
                "Attention, total production is less than quantity. Production: {total, number}/{qty, number}",
            },
            { total, qty: phase.qty }
          ),
        },
      });
    }
    // If partial delivery and TOTAL > QTY and QTY > 0 displays a warning
    if (
      delivery.status === "INTERRUPT" &&
      total > +phase.qty &&
      +phase.qty > 0
    ) {
      return confirmAction({
        async confirm() {
          dispatch(setProduced(produced));
          dispatch(setSecondProduced(secondProduced));
          await saveAndNext(produced);
        },
        options: {
          text: intl.formatMessage(
            {
              id: "app.pages.application.produced.confirm.greater_than_qty",
              defaultMessage:
                "Attention, total production is greater than quantity. Production: {total, number}/{qty, number}",
            },
            { total, qty: phase.qty }
          ),
        },
      });
    }
    dispatch(setProduced(produced));
    dispatch(setSecondProduced(secondProduced));

    await saveAndNext(produced, secondProduced);
  }

  async function saveAndNext(value, secondValue) {
    await mutate({
      variables: {
        sessionId: currentSession,
        produced: value,
        secondProduced: secondValue,
      },
    });

    if (hmiConfiguration.SKIP_SCRAP) {
      if (hmiConfiguration.SKIP_PICKED) {
        return history.push(`/application/notes/${phaseId}/${sessionId}`);
      }
      return history.push(`/application/picked/${phaseId}/${sessionId}`);
    }
    return history.push(`/application/scrap/${phaseId}/${sessionId}`);
  }

  const { PRODUCTIVITY_INPUT_ENABLED } = phase.configuration;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Produced - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={delivery.status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id={`app.pages.application.produced.title.${phase.unit}`}
                    defaultMessage="How many pieces?"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div
                className={classNames(
                  "column",
                  PRODUCTIVITY_INPUT_ENABLED ? "is-4" : "is-3"
                )}
              >
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  {!Boolean(phase.secondQty) && PRODUCTIVITY_INPUT_ENABLED && (
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.blueyGrey}
                        onClick={() => {
                          dispatch(
                            setProduced(phase.missing + session.produced)
                          );
                          firstInputRef.current.value = `${
                            phase.missing + session.produced
                          }`;
                        }}
                        data-cy="application-button-missing"
                      >
                        <FormattedMessage
                          id="app.pages.application.produced.missing_qty"
                          defaultMessage="{value, number} {value, plural, one {piece} other {pieces}}"
                          values={{ value: phase.missing + session.produced }}
                        />
                      </MediumButton>
                    </div>
                  )}
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(isError && "animated shake duration400")}
            >
              <InputsWrap hasSecondUnit={Boolean(phase.secondUnit)}>
                <NumberField
                  cy="application-produced-input"
                  autofocus={true}
                  key="produced"
                  placeholder="0"
                  disabled={!PRODUCTIVITY_INPUT_ENABLED}
                  innerRef={firstInputRef}
                  defaultValue={
                    delivery.session.produced || session.produced || 0
                  }
                  onChange={(value) => {
                    dispatch(setProduced(value));
                    setConvVactor(() => {
                      const newConvFactor = getConvFactor();

                      return newConvFactor ? newConvFactor : "-";
                    });
                    if (
                      secondInputRef.current &&
                      Boolean(phase.secondQtyConversion)
                    ) {
                      secondInputRef.current.value = parseFloat(
                        (value * phase.secondQtyConversion).toFixed(2)
                      );
                    }
                  }}
                />
                {Boolean(phase.secondUnit) && (
                  <NumberField
                    disabled
                    defaultValue={phase.unit}
                    placeholder={phase.unit}
                  />
                )}
              </InputsWrap>
            </div>

            {secondUnit ? (
              <div
                className={classNames(isError && "animated shake duration400")}
              >
                <Title.H5>
                  <FormattedMessage
                    id="app.pages.application.produced.second_qty.title"
                    defaultMessage="Secondary quantity"
                  />
                </Title.H5>
                <InputsWrap hasSecondUnit={Boolean(phase.secondUnit)}>
                  <NumberField
                    key="secondProduced"
                    placeholder="0"
                    innerRef={secondInputRef}
                    onChange={(value) => {
                      dispatch(setSecondProduced(value));
                      setConvVactor(() => {
                        const newConvFactor = getConvFactor();

                        return newConvFactor ? newConvFactor : "-";
                      });
                      if (
                        firstInputRef.current &&
                        Boolean(phase.secondQtyConversion)
                      ) {
                        firstInputRef.current.value = parseFloat(
                          (value / phase.secondQtyConversion).toFixed(2)
                        );
                      }
                    }}
                  />
                  <NumberField
                    disabled
                    defaultValue={phase.secondUnit}
                    placeholder={phase.secondUnit}
                  />
                </InputsWrap>
                <Subtitle.S5 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.produced.conversion.title"
                    defaultMessage="Conversion factor"
                    values={{
                      value: phase.secondQtyConversion
                        ? phase.secondQtyConversion
                        : convFactor,
                    }}
                  />
                </Subtitle.S5>
                <ButtonRemove
                  onClick={() => {
                    setSecondUnit(false);
                    if (secondInputRef.current) {
                      secondInputRef.current.value = 0;
                    }
                  }}
                >
                  <IconRemove />
                  <FormattedMessage
                    id="app.shared.remove"
                    defaultMessage="Remove"
                  />
                </ButtonRemove>
              </div>
            ) : (
              Boolean(phase.secondUnit) &&
              phase.unit !== phase.secondUnit && (
                <Button onClick={() => setSecondUnit(true)}>
                  <IconScales />
                  <FormattedMessage
                    id="app.pages.scheduling.phase.add.second_qty.title"
                    defaultMessage="Add secondary quantity"
                  />
                </Button>
              )
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Produced.propTypes = {};

export default injectIntl(Produced);

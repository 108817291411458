import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Title from "../../../../components/Title";
import { MediumButton } from "../../../../components/Button";
import themes, { buttons } from "../../../../libs/themes";
import Background from "../../../../components/Layout";
import { SmallTag } from "../../../../components/Tag";
import Header from "../../../../components/Header";
import StepHeader from "../../../../components/StepHeader";
import NumberField from "../../../../components/Fields/NumberField";

function SupplyItemBatch() {
  const inputEl = useRef(null);
  const history = useHistory();
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const theme = themes.materialViolet;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Supply Item Batch - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag
                  isUpperCase
                  isLight
                  theme={themes.materialViolet.buttons}
                >
                  <FormattedMessage
                    id="app.shared.material"
                    defaultMessage="Material"
                  />
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_item_batch.title"
                    defaultMessage="Insert or scan batch"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={() => history.goBack()}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      // onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column has-text-centered">
                <NumberField
                  cy="application-button-batch-input"
                  autofocus={true}
                  key="add-batch"
                  placeholder={0}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItemBatch.propTypes = {};

export default SupplyItemBatch;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function hasFeature(feature, enabledFeatures) {
  // Split by comma, space or both;
  const features = feature.split(/,| /);
  let enabled = false;
  features.forEach((featureName) => {
    const regex = new RegExp(featureName);
    if (enabledFeatures instanceof Array) {
      if (enabledFeatures.some((feature) => regex.test(feature))) {
        enabled = true;
      }
    } else {
      if (enabledFeatures instanceof Object) {
        if (
          Object.keys(enabledFeatures).some((feature) => regex.test(feature))
        ) {
          const foundedFeatures = Object.keys(enabledFeatures).filter((key) =>
            regex.test(key)
          );
          enabled = foundedFeatures.some((key) => enabledFeatures[key]);
        }
      }
    }
  });
  return enabled;
}

/**
 * Checks if feature is enabled
 *
 * @param feature
 * @param children
 * @return {*}
 * @constructor
 */
function HasFeature({ feature, children }) {
  const { features } = useSelector(({ config }) => config);
  // Check if feature is enabled with regex
  if (hasFeature(feature, features)) {
    return <>{children}</>;
  }
  return <span />;
}

HasFeature.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default HasFeature;

export { hasFeature };

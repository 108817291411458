import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";

const TableHead = styled.div`
  padding: ${({ isSmall }) =>
    !isSmall ? "0.8rem 2.2rem 0.8rem 2.2rem" : "0.4rem 1.4rem 0.4rem 1.4rem"};
  margin-bottom: 0.8rem;
  font-size: 2.2rem !important;
  background-color: #f5f5f5;
  border-radius: 0.8rem;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
`;

function TableHeader({ children, isSmall, ...props }) {
  return (
    <TableHead
      className="columns is-vcentered is-fullwidth is-marginless-right is-marginless-left"
      style={props.style}
    >
      {children}
    </TableHead>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node,
};
export default compose(withTheme)(TableHeader);

import PropTypes from "prop-types";
import React from "react";

import NumberField from "../../../../../components/Fields/NumberField";

function AlertThreshold({ qty, setQty }) {
  return (
    <>
      <div className="columns">
        <div className="column has-text-centered">
          <NumberField
            cy="application-supply_settings-qty-input"
            key="treshold"
            placeholder={0}
            onChange={(value) => setQty(value)}
          />
        </div>
      </div>
    </>
  );
}

AlertThreshold.propTypes = {
  qty: PropTypes.number,
  setQty: PropTypes.func.isRequired,
};
export default AlertThreshold;

import { useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setMachine, setProgram } from "../../../../actions";
import Button from "../../../../components/Button";
import Clock from "../../../../components/Clock";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import BackImage from "../../../../images/arrow-back-gray-icn.svg";
import Machines from "./components/Machines";

import { loader } from "graphql.macro";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../../libs/themes";
const MACHINES = loader("./AdvancedMachine.query.graphql");

function AdvancedMachine() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId, phaseId } = useParams();
  const { machineId, stationId } = useSelector(({ config }) => config);

  const { data, loading, error } = useQuery(MACHINES, {
    variables: { stationId },
  });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { machines } = data;

  function onClick(id) {
    dispatch(setProgram());
    if (machineId === id) {
      return dispatch(setMachine());
    }
    dispatch(setMachine(id));
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Choose machine - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() =>
                    history.push(
                      `/configuration/advanced/menu/${jobId}/${phaseId}`
                    )
                  }
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.machines.title"
                    defaultMessage="Choose machine"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.machines.subtitle"
                    defaultMessage="Tell me on which machine you will work"
                  />
                </Subtitle.S3>

                <Machines onClick={onClick} machines={machines} />
                {machines.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.machines.empty"
                      defaultMessage="Sorry, no machines available"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default AdvancedMachine;

import React from "react";
import styled from "styled-components";

const StyledBodyContainer = styled.div`
  align-items: stretch !important;
  //align-self: stretch;
  max-height: calc(
    100vh - ${({ isFullHeight }) => (isFullHeight ? "14.8rem" : "23.8rem")}
  );
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem 1.5rem;
  display: ${({ isBodyCentered }) => (isBodyCentered ? "flex" : "block")};
  justify-content: ${({ isBodyCentered }) =>
    isBodyCentered ? "center" : "flex-start"};
  background-position: center;
  background-size: 60%;
  > img {
    max-height: calc(100vh - 40rem);
    margin: auto;
    transform: translateY(-4rem);
  }
`;

function BodyContainer({ children, ...props }) {
  return (
    <StyledBodyContainer className="hero-body" {...props}>
      {props.backgroundImage && <img src={props.backgroundImage} alt="" />}
      {children}
    </StyledBodyContainer>
  );
}

BodyContainer.propTypes = {};
export default BodyContainer;

import React from "react";
import PropTypes from "prop-types";
import config from "../config";

function SalesOrder({ salesOrderId, meta }) {
  if (meta && config.customer === "it-next") {
    return <span>{meta.customerOrder}</span>;
  }
  return <span>{salesOrderId}</span>;
}

SalesOrder.propTypes = {
  salesOrderId: PropTypes.string,
  meta: PropTypes.shape({
    customerOrder: PropTypes.string,
  }),
};
export default SalesOrder;

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import OnScreenKeyboard from "../OnScreenKeyboard";

function TextareaField({
  placeholder,
  onChange,
  defaultValue,
  autofocus = false,
  cy,
  isError = false,
}) {
  const inputEl = useRef(null);
  const [showKeyboard, setShowKeyboard] = useState(false);

  useEffect(() => {
    if (autofocus) {
      inputEl && inputEl.current && inputEl.current.select();
    }
  }, [inputEl]);

  return (
    <>
      <div className="columns">
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <div className="columns">
            <div className="column has-text-centered">
              <div className="separator" />
              <textarea
                name="textarea-field"
                id="textarea-field"
                data-cy={cy ?? "application-textarea-input"}
                rows="6"
                className="title is-4"
                value={defaultValue ?? ""}
                onFocus={() => {
                  if (inputEl.current) {
                    inputEl.current.select();
                    setShowKeyboard(true);
                  }
                }}
                placeholder={placeholder ?? ""}
                onInput={(event) => onChange(event.target.value)}
                ref={inputEl}
              />
            </div>
          </div>
        </div>
      </div>
      {showKeyboard && <OnScreenKeyboard inputNode={inputEl.current} />}
    </>
  );
}

TextareaField.propTypes = {};

export default TextareaField;

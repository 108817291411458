import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";

import { useHistory } from "react-router-dom";
import NumberField from "../../../../../components/Fields/NumberField";
import HmiField from "../../../../../components/HmiField/HmiField";
import toast from "../../../../../libs/toast";

function ContainerLoadStep2({ scrap, setScrap, container, setStep }) {
  const intl = useIntl();
  const history = useHistory();
  function onNext() {
    if (isNaN(scrap)) {
      toast({
        title: intl.formatMessage({ id: "app.shared.nan" }),
        icon: "error",
      });
      return;
    }
    setStep(3);
  }
  return (
    <HmiField
      title={intl.formatMessage({
        id: "app.pages.application.container_load.scrap.title",
        defaultMessage: "How many scrap?",
      })}
      subtitle={container.name}
      subtitle2={
        container.item && `${container.item.code} :: ${container.item.name}`
      }
      field={
        <NumberField
          key="scrap"
          placeholder={scrap || 0}
          defaultValue={scrap || 0}
          onChange={(value) => {
            setScrap(+value);
          }}
        />
      }
      value={scrap || 0}
      onBack={() => history.goBack()}
      onNext={onNext}
    />
  );
}

ContainerLoadStep2.propTypes = {
  scrap: PropTypes.number,
  setScrap: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ContainerLoadStep2;

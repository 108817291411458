import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { useIntl } from "react-intl";

const StyledContainer = styled.div`
  .sk-circle .sk-child:before {
    background: ${({ backgroundColor }) => backgroundColor || "#455a64"};
  }
`;

function Loader({
  message = "app.shared.loading",
  isLarge,
  isSmall,
  className,
  ...props
}) {
  const intl = useIntl();
  return (
    <StyledContainer className={className} {...props}>
      <span
        className={classNames(
          "sk-circle",
          isLarge && "is-large",
          isSmall && "is-small"
        )}
      >
        <div className="sk-circle1 sk-child" />
        <div className="sk-circle2 sk-child" />
        <div className="sk-circle3 sk-child" />
        <div className="sk-circle4 sk-child" />
        <div className="sk-circle5 sk-child" />
        <div className="sk-circle6 sk-child" />
        <div className="sk-circle7 sk-child" />
        <div className="sk-circle8 sk-child" />
        <div className="sk-circle9 sk-child" />
        <div className="sk-circle10 sk-child" />
        <div className="sk-circle11 sk-child" />
        <div className="sk-circle12 sk-child" />
      </span>
      {message && intl.formatMessage({ id: message })}
    </StyledContainer>
  );
}

Loader.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  isLarge: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default Loader;

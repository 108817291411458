import PropTypes from "prop-types";
import React from "react";
import Pagination from "react-paginating";

import Button from "../../../../../components/Button";
import Operator from "./Operator";

const PAGE_LIMIT = 8;

// Transform to new React
function Operators({ operators, onClick }) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const paginate = (operators, pageNumber) => {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return operators.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  };
  const total = operators.length;
  const pageCount = Math.ceil(total / PAGE_LIMIT);
  return (
    <Pagination
      total={total}
      limit={PAGE_LIMIT}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps,
      }) => (
        <div className="columns">
          <div className="column is-narrow is-flex">
            {hasPreviousPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange: handlePageChange,
                })}
              >
                <i
                  className="icon-arrow-left-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
          <div className="column">
            <div className="columns is-multiline">
              {paginate(operators, currentPage).map((operator) => (
                <Operator
                  operator={operator}
                  key={operator._id}
                  onClick={() => onClick(operator._id)}
                />
              ))}
            </div>
          </div>
          <div className="column is-narrow is-flex">
            {hasNextPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange: handlePageChange,
                })}
              >
                <i
                  className="icon-arrow-right-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
        </div>
      )}
    </Pagination>
  );
}

Operators.propTypes = {
  operators: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pin: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.string,
    })
  ),
};
export default Operators;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import classNames from "classnames";
import flatMap from "lodash.flatmap";

import SessionNote from "./SessionNote";

const StyledContainer = styled.div`
  white-space: pre-line;
  .tabs {
    ul {
      border-bottom-color: transparent;
      li {
        a {
          border-bottom-width: 0.4rem;
          border-bottom-color: transparent;
          text-decoration: none !important;
          font-size: 1.8rem;
        }
        &.is-active a {
          border-bottom-color: white;
          font-weight: 800;
        }
      }
    }
  }
  .content {
    padding: 1.5rem;
    overflow-y: auto;
    hr:last-child {
      display: none;
    }
  }
`;

function NotesBody({ phase }) {
  const { description, job, sessions, dependencies } = phase;
  const sessionNotes = sessions.filter((s) => s.notes && s.notes.length > 0);
  const dependenciesNotes = dependencies.filter((d) =>
    d.phase.sessions.some((s) => s.notes && s.notes.length > 0)
  );
  const hasNotes =
    description ||
    job.description ||
    sessionNotes.length > 0 ||
    dependenciesNotes.length > 0;
  const [active, setActive] = useState(
    !hasNotes
      ? null
      : description
      ? "operation"
      : job.description
      ? "order"
      : sessionNotes.length > 0
      ? "sessions"
      : "dependencies"
  );
  return (
    <StyledContainer>
      {hasNotes && (
        <div className="tabs is-centered">
          <ul>
            {description && (
              <li className={classNames(active === "operation" && "is-active")}>
                <a onClick={() => setActive("operation")}>
                  <FormattedMessage
                    id="app.components.notes.operation"
                    defaultMessage="Operation"
                  />
                </a>
              </li>
            )}
            {job.description && (
              <li className={classNames(active === "order" && "is-active")}>
                <a onClick={() => setActive("order")}>
                  <FormattedMessage
                    id="app.components.notes.order"
                    defaultMessage="From order"
                  />
                </a>
              </li>
            )}
            {sessionNotes.length > 0 && (
              <li className={classNames(active === "sessions" && "is-active")}>
                <a onClick={() => setActive("sessions")}>
                  <FormattedMessage
                    id="app.components.notes.sessions"
                    defaultMessage="Previous sessions ({value, number})"
                    values={{ value: sessionNotes.length }}
                  />
                </a>
              </li>
            )}
            {dependenciesNotes.length > 0 && (
              <li
                className={classNames(active === "dependencies" && "is-active")}
              >
                <a onClick={() => setActive("dependencies")}>
                  <FormattedMessage
                    id="app.components.notes.dependencies"
                    defaultMessage="Dependencies"
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
      <div className="content">
        {hasNotes ? (
          <>
            {active === "operation" && (
              <h4 className="title is-4">{description}</h4>
            )}
            {active === "order" && (
              <h4 className="title is-4">{job.description}</h4>
            )}
            {active === "sessions" &&
              sessionNotes.map((session) => (
                <SessionNote
                  key={session._id}
                  date={session.createdAt}
                  notes={session.notes}
                  worker={session.worker}
                />
              ))}
            {active === "dependencies" &&
              flatMap(dependenciesNotes, (dependency) =>
                dependency.phase.sessions
                  .filter((s) => s.notes && s.notes.length > 0)
                  .map((session) => (
                    <SessionNote
                      key={session._id}
                      phase={dependency.phase}
                      date={session.createdAt}
                      notes={session.notes}
                      worker={session.worker}
                    />
                  ))
              )}
          </>
        ) : (
          <h4 className="title is-4">
            <FormattedMessage
              id="app.components.notes.placeholder.read"
              defaultMessage="No notes"
            />
          </h4>
        )}
      </div>
    </StyledContainer>
  );
}

NotesBody.propTypes = {
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    description: PropTypes.string,
    job: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }).isRequired,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string,
        notes: PropTypes.string,
        worker: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    dependencies: PropTypes.arrayOf(
      PropTypes.shape({
        phase: PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          sort: PropTypes.number.isRequired,
          product: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            //name: PropTypes.string.isRequired,
          }),
          sessions: PropTypes.arrayOf(
            PropTypes.shape({
              createdAt: PropTypes.string,
              notes: PropTypes.string,
              worker: PropTypes.shape({
                _id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
              }).isRequired,
            })
          ).isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
};
export default NotesBody;

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Context from "./ListElemModal.Context";

const ModalContainer = styled.div`
  .modal-container {
    height: calc(100vh - 4rem);
    margin-top: 2rem;
    margin-bottom: 2rem;
    @keyframes modal-show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .modal-background {
      animation: modal-show 1s ease;
      animation-delay: 500ms;
    }

    .modal-back {
      animation: modal-show 1s ease;
      //opacity: 0;
      //animation: modal-show 1s ease;
      //animation-delay: 500ms;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      cursor: pointer;
      margin-bottom: 2rem;

      .content {
        width: 100%;
        height: 100%;
        position: relative;
        padding-right: 0;
        .back-text {
          position: absolute;
          bottom: 2rem;
          width: 100%;
          font-weight: 400;
        }
        img.arrow_back_icn {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 3.852rem;
          height: 2.902rem;
          margin: 0 0.867rem 17.036rem 0.531rem;
          object-fit: contain;
          background-color: transparent;
        }
      }
    }
    .modal-main {
      animation: modal-show 0.5s ease;
      padding-right: 4.8rem;
      width: 100vw;
      .head {
        align-items: flex-start;
        flex-grow: 0;
        background-color: transparent;
      }
      .body {
        padding: 2.5rem;
        margin-top: -2.4rem;
        margin-bottom: -3.2rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .content {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

/**
 * Component that shows an image, when clicked shows a modal
 */
function ListElemModal({ children, head, button, active }) {
  const [isOpen, setIsOpen] = useState(active);
  return (
    <Context.Provider value={{ isOpen, setIsOpen }}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
          return false;
        }}
      >
        {button}
      </div>
      <ModalContainer className={classNames("modal", isOpen && "is-active")}>
        <div
          className="modal-background"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <div className="tile is-ancestor modal-container">
          <div className="tile is-parent is-2 modal-back">
            <article
              className="tile is-child notification is-danger is-fullheight"
              onClick={(e) => {
                //history.replace(history.location.pathname);
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <div className="content">
                <img
                  src={require("./assets/arrow-back-icn.svg").default}
                  className="arrow_back_icn"
                  alt="back"
                />
                <p className="title is-4 has-text-centered back-text">
                  <FormattedMessage
                    id="app.shared.back_lowercase"
                    defaultMessage="back"
                  />
                </p>
              </div>
            </article>
          </div>
          <div className="tile is-parent is-vertical modal-main">
            <article className="tile is-child notification head">
              <div className="content">{head}</div>
            </article>
            <article className="tile notification body">
              <div className="content is-fullwidth">{children}</div>
            </article>
          </div>
        </div>
      </ModalContainer>
    </Context.Provider>
  );
}

ListElemModal.propTypes = {
  head: PropTypes.node,
  button: PropTypes.node,
  children: PropTypes.node,
  active: PropTypes.bool,
};
export default ListElemModal;

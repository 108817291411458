import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import Button, { MediumButton } from "../../../components/Button";
import NumberField from "../../../components/Fields/NumberField";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import ListElement from "../../../components/ListElement";
import LoadingIndicator from "../../../components/LoadingIndicator";
import OnScreenKeyboard from "../../../components/OnScreenKeyboard";
import StepHeader from "../../../components/StepHeader";
import TableHeader from "../../../components/TableHeader";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../libs/error-logger";
import NumberFormatter, { RoundValue } from "../../../libs/format-number";
import themes, { buttons } from "../../../libs/themes";
import GenericError from "../../errors/GenericError";

const PICKED_QUERY = loader("./Picked.query.graphql");
const PICKED_MUTATION = loader("./Picked.mutation.graphql");

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  &.main {
    color: #d9e0ec;
    background-color: #4a4a4a;
  }
`;

const ListColumns = styled.div`
  .column {
    text-overflow: ellipses;
  }
  .counter {
    width: 16.4rem;
    font-size: 4.2rem;
    padding-left: 2.4rem;
    height: 4.4rem;
    line-height: 2.6rem;
    display: flex;
    flex-direction: horizontal;
    justify-content: end;
    align-items: end;
    text-overflow: ellipses;

    span.unit {
      font-size: 1.6rem;
      margin-left: 0.8rem;
    }

    &.has-right-border {
      border-right: 0.3px solid #70707020;
      padding-right: 0;
    }

    div.edited {
      opacity: 0.7;
      text-align: right;
      text-decoration: line-through;
      font-weight: 300;
      margin-right: 1.2rem;
      content: "cc";
    }
    &.error {
      p {
        > div {
          background-color: #efd9e2;
        }
      }
    }
  }
`;

const TableHeadCounter = styled.div`
  min-width: 21.4rem;
  padding-left: 0.6rem;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
`;

const Counter = styled.div`
  min-width: 21.4rem;
  max-width: 21.4rem;
  font-size: 2.6rem;
  height: 4.4rem;
  line-height: 2.8rem;
  display: flex;
  flex-direction: horizontal;
  justify-content: start;
  align-items: baseline;
  padding-left: 3.2rem;
  text-overflow: ellispses;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
  > span {
    font-size: 2.2rem;
    font-weight: 400;
    opacity: 0.7;
  }
  span.unit {
    font-size: 2.2rem;
    font-weight: 500;
    opacity: 1 !important;
  }
  span.missing {
    font-size: 2.2rem;
    font-weight: 400;
    opacity: 0.65;
  }
  input {
    font-size: 5.2rem !important;
  }
`;

const InputCounter = styled.div`
  min-width: 21.4rem;
  max-width: 21.4rem;
  font-size: 2.6rem;
  height: 4.4rem;
  line-height: 2.8rem;
  display: flex;
  flex-direction: horizontal;
  justify-content: start;
  align-items: baseline;
  padding-left: 0;
  text-overflow: ellispses;
  &.has-right-border {
    border-right: 0.03rem solid rgba(0, 0, 0, 0.4);
  }
  > span {
    font-size: 2.2rem;
    font-weight: 400;
    opacity: 0.7;
  }
  span.unit {
    font-size: 2.2rem;
    font-weight: 500;
    opacity: 1 !important;
  }
  span.missing {
    font-size: 2.2rem;
    font-weight: 400;
    opacity: 0.65;
  }
  input {
    font-size: 4.2rem !important;
    padding: 1.78rem 2.5rem 1.78rem 1.5rem;
  }
`;

function Picked() {
  const [showKeyboard, setShowKeyboard] = useState(false);
  const inputEl = useRef(null);
  const [picked, setPicked] = useState(null);
  const [scrap, setScrap] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const [editPicking, setEditPicking] = useState(null);
  const { sessionId, phaseId } = useParams();
  const [currentSession] = useState(sessionId);
  const history = useHistory();
  const { delivery } = useSelector(({ delivery, config }) => ({
    delivery,
    config,
  }));

  const variables = { sessionId: currentSession, phaseId };

  const { loading, error, data, refetch } = useQuery(PICKED_QUERY, {
    variables,
  });
  const [mutate] = useMutation(PICKED_MUTATION);

  const theme = themes.default;

  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { session } = data;

  async function updatePicked(pickingId) {
    try {
      const variables = {
        sessionId,
        pickingId,
      };
      if (picked !== null) variables.overwritePicked = picked;
      if (scrap !== null) variables.overwriteScrap = scrap;
      if (remaining !== null) variables.overwriteRemaining = remaining;

      await mutate({ variables });
    } catch (e) {
      onError(e);
    }
    refetch();
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Picked - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            !editPicking ? (
              <div className="columns">
                <div className="column is-1">
                  <Button
                    isFullWidth
                    theme={themes.default.buttons}
                    onClick={() => history.goBack()}
                    data-cy="application-button-back"
                  >
                    <img
                      src={
                        require("../../../images/arrow-back-gray-icn.svg")
                          .default
                      }
                      alt="Back"
                    />
                  </Button>
                </div>
                <div className="column is-3"></div>
                <div className="column is-4">
                  <Button
                    isFullWidth
                    theme={themes.primary.buttons}
                    onClick={() => {
                      history.push(
                        `/application/notes/${phaseId}/${sessionId}`
                      );
                    }}
                    data-cy="application-button-picked-confirm"
                  >
                    <FormattedMessage
                      id="app.pages.configuration.operations.confirm"
                      defaultMessage="Confirm"
                    />
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )
          }
        >
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={delivery.status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  {!editPicking ? (
                    <FormattedMessage
                      id="app.pages.application.picked.qty.title"
                      defaultMessage="How many units?"
                    />
                  ) : (
                    <FormattedMessage
                      id="app.pages.application.container_load.qty.title"
                      defaultMessage="How many units?"
                    />
                  )}
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              {editPicking && (
                <div className="column is-3">
                  <div className="columns">
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.default}
                        onClick={() => {
                          setEditPicking(null);
                        }}
                        data-cy="application-button-back"
                      >
                        <FormattedMessage
                          id="app.shared.back"
                          defaultMessage="Back"
                        />
                      </MediumButton>
                    </div>
                    <div className="column">
                      <MediumButton
                        isFullWidth
                        theme={buttons.productionBlue}
                        onClick={() => {
                          updatePicked(editPicking._id);
                          setEditPicking(null);
                        }}
                        data-cy="application-button-ok"
                      >
                        <FormattedMessage
                          id="app.shared.ok"
                          defaultMessage="Ok"
                        />
                      </MediumButton>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <>
              {!editPicking && (
                <h4 className="title is-4 w500">
                  <FormattedMessage
                    id="app.components.docs.doc_default.doc.PICKING"
                    defaultMessage="Picking list"
                  />
                  <div
                    className="separator"
                    style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }}
                  />
                </h4>
              )}

              <TableHeader style={{ paddingRight: "0" }}>
                <div className="column">
                  <p className="title is-5">
                    <FormattedMessage
                      id="app.pages.application.material.table.item"
                      defaultMessage="Item"
                    />
                  </p>
                </div>
                <TableHeadCounter className="column is-narrow has-right-text">
                  <p className="title is-5">
                    <i className="icon-st-machines-icn" />{" "}
                    <FormattedMessage
                      id="app.pages.application.material.table.picked"
                      defaultMessage="Picked"
                    />
                  </p>
                </TableHeadCounter>
                <TableHeadCounter className="column is-narrow has-right-text">
                  <p className="title is-5">
                    <i className="icon-giphy-delete-white" />{" "}
                    <FormattedMessage
                      id="app.pages.application.material.table.scrap"
                      defaultMessage="Scrap"
                    />
                  </p>
                </TableHeadCounter>
                <TableHeadCounter className="column is-narrow has-right-text">
                  <p className="title is-5">
                    <i className="icon-st-containers-icn" />{" "}
                    <FormattedMessage
                      id="app.pages.application.material.table.remaining"
                      defaultMessage="Remaining"
                    />
                  </p>
                </TableHeadCounter>
              </TableHeader>
            </>

            {!editPicking &&
              session.picking.map((picking) => {
                const containersCurrent = picking.containers.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.current,
                  0
                );

                var pickingRemaining = containersCurrent;
                var pickingRemainingReal = pickingRemaining;
                if (pickingRemainingReal < 0) pickingRemainingReal = 0;

                return (
                  <ListElement
                    onClick={() => {
                      setPicked(picking.overwritePicked);
                      setScrap(picking.overwriteScrap);
                      setRemaining(picking.overwriteRemaining);
                      setEditPicking(picking);
                    }}
                    theme={
                      picking.remaining < 0
                        ? themes.errorRed.listElement
                        : theme.listElement
                    }
                    data-cy="application-supply-item"
                    data-cy-id={picking._id}
                    key={picking._id}
                    icon={picking.item.image}
                  >
                    <span style={{ fontSize: "0.8rem" }}></span>
                    <ListColumns
                      className={
                        "columns is-vcentered is-fullwidth is-marginless"
                      }
                    >
                      <div className="column">
                        <p className="subtitle is-6">
                          {picking.main && (
                            <Badge className="main">
                              <i className="icon-star-empty" />
                              <FormattedMessage
                                id="app.pages.application.material.table.main"
                                defaultMessage="Main item"
                              />
                            </Badge>
                          )}

                          {picking.container && (
                            <Badge>
                              <i className="icon-st-containers-icn" />
                              {picking.container?.name}
                            </Badge>
                          )}
                          {picking.item.code && (
                            <Badge>
                              <i className="icon-product" />
                              {picking.item.code}
                            </Badge>
                          )}
                        </p>
                        <p className="title is-4">{picking.item.name}</p>
                      </div>

                      <Counter className="column is-narrow">
                        <>
                          <NumberFormatter value={RoundValue(picking.picked)} />
                          &nbsp;
                          <span className="unit">{picking.item.unit}</span>
                        </>
                      </Counter>

                      <Counter className="column is-narrow">
                        <NumberFormatter value={RoundValue(picking.scrap)} />
                        &nbsp;
                        <span className="unit">{picking.item.unit}</span>
                      </Counter>

                      <Counter className="column is-narrow">
                        <NumberFormatter
                          value={RoundValue(picking.remaining)}
                        />
                        &nbsp;
                        <span className="unit">{picking.item.unit}</span>{" "}
                        &nbsp;&nbsp;
                        <span className="missing">
                          {pickingRemaining < 0 && (
                            <>
                              {pickingRemaining}{" "}
                              <span className="unit">{picking.item.unit}</span>
                            </>
                          )}
                        </span>
                      </Counter>
                    </ListColumns>
                  </ListElement>
                );
              })}
            {editPicking && (
              <>
                <div className="columns">
                  <div
                    className="column"
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: "calc(100vw - 75.5rem)",
                    }}
                  >
                    <ListElement
                      theme={
                        (remaining ?? editPicking.remaining) < 0
                          ? themes.errorRed.listElement
                          : theme.listElement
                      }
                      data-cy="application-supply-item"
                      data-cy-id={editPicking._id}
                      key={editPicking._id}
                      icon={editPicking.item.image}
                    >
                      <ListColumns
                        className={
                          "columns is-vcentered is-fullwidth is-marginless"
                        }
                      >
                        <div className="column">
                          <p className="subtitle is-6">
                            {editPicking.main && (
                              <Badge className="main">
                                <i className="icon-star-empty" />
                                <FormattedMessage
                                  id="app.pages.application.material.table.main"
                                  defaultMessage="Main item"
                                />
                              </Badge>
                            )}

                            {editPicking.container && (
                              <Badge>
                                <i className="icon-st-containers-icn" />
                                {editPicking.container?.name}
                              </Badge>
                            )}
                            {editPicking.item.code && (
                              <Badge>
                                <i className="icon-product" />
                                {editPicking.item.code}
                              </Badge>
                            )}
                          </p>
                          <p className="title is-4">{editPicking.item.name}</p>
                        </div>
                      </ListColumns>
                    </ListElement>
                  </div>
                  <InputCounter className="column is-narrow">
                    <NumberField
                      cy="application-notes-input"
                      defaultValue={editPicking?.overwritePicked}
                      onChange={(val) => {
                        setPicked(val);
                      }}
                      autofocus={false}
                      placeholder={editPicking?.picked}
                    />
                  </InputCounter>

                  <InputCounter className="column is-narrow">
                    <NumberField
                      cy="application-notes-input"
                      defaultValue={editPicking?.overwriteScrap}
                      onChange={(val) => {
                        setScrap(val);
                      }}
                      autofocus={false}
                      placeholder={editPicking.scrap}
                    />
                  </InputCounter>

                  <InputCounter className="column is-narrow">
                    <NumberField
                      cy="application-notes-input"
                      defaultValue={editPicking?.overwriteRemaining}
                      onChange={(val) => {
                        setRemaining(val);
                      }}
                      autofocus={false}
                      placeholder={editPicking?.remaining}
                    />
                  </InputCounter>
                </div>
                <div className="columns">
                  <div className="column">
                    {showKeyboard && (
                      <OnScreenKeyboard
                        inputNode={inputEl.current}
                        layouts={["numeric"]}
                        onClose={() => setShowKeyboard(false)}
                        fullScreen={true}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Picked.propTypes = {};

export default Picked;

import React from "react";
import PropTypes from "prop-types";

export const Unit = ({ unit = "unit" }) => {
  return (
    <Choose>
      <When condition={unit === "unit"}>
        <i className="icon-giphy-measurement-units" />
      </When>
      <When condition={unit === "lt"}>
        <i className="icon-giphy-measurement-liquid" />
      </When>
      <When condition={unit === "kg"}>
        <i className="icon-giphy-measurement-weight" />
      </When>
      <When condition={unit === "mt"}>
        <i className="icon-giphy-measurement-length" />
      </When>
      <When condition={unit === "sqm"}>
        <i className="icon-giphy-measurement-length-double" />
      </When>
      <When condition={unit === "pair"}>
        <i className="icon-giphy-measurement-pair" />
      </When>
      <When condition={unit === "kit"}>
        <i className="icon-giphy-measurement-kit" />
      </When>
      <When condition={unit === "na"}>
        <i />
      </When>
      <Otherwise>
        <i className="icon-giphy-measurement-units" />
      </Otherwise>
    </Choose>
  );
};

Unit.propTypes = {
  unit: PropTypes.oneOf(["lt", "kg", "unit", "mt", "sqm", "pair", "kit"]),
};

export default Unit;

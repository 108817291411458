import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { default as React, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { setLoading } from "../../../../actions";
import Button from "../../../../components/Button";
import NumberField from "../../../../components/Fields/NumberField";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import ListElement from "../../../../components/ListElement";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ProgressBar from "../../../../components/ProgressBar";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../../libs/error-logger";
import NumberFormatter from "../../../../libs/format-number";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";

const CONTAINER_CONTENT = loader("./ContainerEdit.query.graphql");
const SET_CONTAINER_EDIT = loader("./ContainerEdit.mutation.graphql");

const ProgressBarContainer = styled.div`
  padding: 0.8rem 2.8rem 2.2rem 2.2rem;
  background-color: #f6f6f6;
  border-radius: 0.8rem;
`;

const InfoListElem = styled(ListElement)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0.5rem;
  //background-color: #f5f5f5;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
  .edit {
    position: absolute;
    top: 2rem;
    right: 2.2rem;
    font-size: 2.4rem;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .label {
    font-size: 1.8rem;
    opacity: 0.6;
    transform: translateY(-0.4rem);
    //line-height: 2.8rem;
  }
`;

function ContainerEdit() {
  const { containerId, sessionId } = useParams();
  const [current, setCurrent] = useState();
  const dispatch = useDispatch();
  const intl = useIntl();

  const history = useHistory();
  const variables = { containerId };
  const { data, loading, error } = useQuery(CONTAINER_CONTENT, { variables });
  const [mutate] = useMutation(SET_CONTAINER_EDIT, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const theme = themes.neutralGrey;
  const { container } = data;

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      var notes = "";
      await mutate({
        variables: { current, notes },
      });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  return (
    <>
      <Helmet>
        <title>Supply Item - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <Button
                  isFullWidth
                  isSmall={true}
                  theme={themes.primary.buttons}
                  onClick={onSubmit}
                  data-cy="application-button-save"
                >
                  <FormattedMessage
                    id="app.shared.save"
                    defaultMessage="Save"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns is-fullwidth">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.secondary.buttons}>
                  <FormattedMessage
                    id="sypply_item.container.title"
                    defaultMessage="Container"
                  />
                </SmallTag>
                <Title.H1>{container?.name ? container.name : "-"}</Title.H1>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <Title.H3>
                  <FormattedMessage
                    id="app.pages.application.container_edit.info"
                    defaultMessage="Alert threshold (units)"
                  />
                </Title.H3>
                <div className="separator" style={{ marginBottom: "1.2rem" }} />

                <InfoListElem
                  withCircle={false}
                  theme={themes.default.listElement}
                  data-cy="application-container-edit-batch"
                  onClick={() =>
                    !container.isVirtual &&
                    history.push(`/application/container-batch/${containerId}`)
                  }
                >
                  {!container.isVirtual && (
                    <div className="edit">
                      <i className="icon-giphy-edit" />
                    </div>
                  )}
                  <div className="columns is-vcentered is-fullwidth is-marginless">
                    <div className="column">
                      <p className="subtitle is-6">
                        <div className="label">
                          <i className="icon-doc-text" />
                          <FormattedMessage
                            id="app.pages.application.container_edit.batch"
                            defaultMessage="Batch"
                          />
                        </div>
                      </p>
                      <p
                        className="title is-4"
                        data-cy="application-container-batch"
                      >
                        {container.batch ? container.batch.code : "-"}
                      </p>
                    </div>
                  </div>
                </InfoListElem>

                {container.batch && (
                  <InfoListElem
                    withCircle={false}
                    theme={themes.default.listElement}
                    data-cy="application-container-edit-supplier"
                    onClick={() =>
                      containerId &&
                      !container.isVirtual &&
                      history.push(
                        `/application/container-batch-supplier/${containerId}/${sessionId}`
                      )
                    }
                  >
                    {!container.isVirtual && (
                      <div className="edit">
                        <i className="icon-giphy-edit" />
                      </div>
                    )}
                    <div className="columns is-vcentered is-fullwidth is-marginless">
                      <div className="column">
                        <p className="subtitle is-6">
                          <div className="label">
                            <i className="icon-sc-suppliers-icn" />
                            <FormattedMessage
                              id="app.pages.application.container_edit.supplier"
                              defaultMessage="Supplier"
                            />
                          </div>
                        </p>
                        <p
                          className="title is-4"
                          data-cy="application-container-supplier"
                        >
                          {container?.batch?.supplier
                            ? container?.batch?.supplier.name
                            : "-"}
                        </p>
                      </div>{" "}
                    </div>
                  </InfoListElem>
                )}

                <InfoListElem
                  withCircle={false}
                  theme={themes.default.listElement}
                  data-cy="application-container-edit-item"
                  onClick={() =>
                    containerId &&
                    !container.isVirtual &&
                    history.push(`/application/container-item/${containerId}`)
                  }
                >
                  {!container.isVirtual && (
                    <div className="edit">
                      <i className="icon-giphy-edit" />
                    </div>
                  )}
                  <div className="columns is-vcentered is-fullwidth is-marginless">
                    <div className="column">
                      <p className="subtitle is-6">
                        <div className="label">
                          <i className="icon-product" />
                          <FormattedMessage
                            id="app.pages.application.container_edit.item"
                            defaultMessage="Item"
                          />
                        </div>
                      </p>
                      <p
                        className="title is-4"
                        data-cy="application-container-item"
                      >
                        {container?.item?.code ? container?.item.code : "-"}
                      </p>
                    </div>{" "}
                  </div>
                </InfoListElem>
              </div>

              <div className="column has-text-left">
                <div className="columns">
                  <div className="column">
                    <Title.H3>
                      <FormattedMessage
                        id="app.pages.application.container_edit.qty"
                        defaultMessage="Alert threshold (units)"
                      />
                    </Title.H3>
                    <NumberField
                      onChange={(value) => setCurrent(Number(value))}
                      placeholder={0}
                      defaultValue={current ?? container?.current}
                      cy="application-supply_settings-qty-input"
                    />

                    <ProgressBarContainer>
                      {
                        <h1 className="title is-2">
                          <div
                            className="title is-4 has-text-weight-bold"
                            style={{ paddingTop: "1.2rem" }}
                          >
                            <div className="columns">
                              <div className="column is-paddingless-bottom">
                                {container?.item ? container.item.name : "-"}
                              </div>
                              <div className="column is-narrow  is-paddingless-bottom">
                                <NumberFormatter value={container.current} />{" "}
                                <small className="subtitle is-4">/</small>{" "}
                                <NumberFormatter value={container.capacity} />{" "}
                                <span className="title is-4">
                                  {container.unit}
                                </span>
                              </div>
                            </div>
                          </div>
                          <ProgressBar
                            title="Unità prodotte"
                            completed={container?.current}
                            total={container.capacity}
                            hasTitle={false}
                            theme={themes.productionBlue}
                          />
                        </h1>
                      }
                    </ProgressBarContainer>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerEdit.propTypes = {};
export default ContainerEdit;

import { useSubscription } from "@apollo/client";
import { useSelector } from "react-redux";

import { loader } from "graphql.macro";
import confirmAction from "../../libs/confirm_action";
import intl from "../../libs/format-message";

const NOTIFICATIONS_SUBSCRIPTION = loader(
  "./Notifications.subscription.graphql"
);

/**
 * Notifications
 */
function Notifications() {
  const { hmiId } = useSelector(({ config }) => config);

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    variables: { hmiId },
    onData: ({ data: subscriptionData }) => {
      console.log("subscriptionData", subscriptionData);
      showNotification(subscriptionData.data.hmiNotifications);
    },
  });

  async function showNotification(notification) {
    console.log("showNotification", notification);
    const { type, message, messageId } = notification;

    await confirmAction({
      confirm: (value) => {},
      options: {
        icon: type,
        showDenyButton: false,
        showCancelButton: false,
        title: intl.formatMessage({
          id: "app.notification.type." + type.toLowerCase(),
          defaultMessage: type.toUpperCase(),
        }),
        text: messageId
          ? intl.formatMessage({ id: messageId, defaultMessage: message })
          : message,
      },
    });
  }

  return null;
}

Notifications.propTypes = {};

export default Notifications;

import React from "react";
import styled from "styled-components";

const StyledS = styled.p`
  color: ${({ theme }) => theme.fg};
`;

const Title = {
  S1: function S1({ children, ...props }) {
    return (
      <StyledS className="subtitle is-1" {...props}>
        {children}
      </StyledS>
    );
  },
  S2: function S2({ children, ...props }) {
    return (
      <StyledS className="subtitle is-2" {...props}>
        {children}
      </StyledS>
    );
  },
  S3: function S3({ children, ...props }) {
    return (
      <StyledS className="subtitle is-3" {...props}>
        {children}
      </StyledS>
    );
  },
  S4: function S4({ children, ...props }) {
    return (
      <StyledS className="subtitle is-4" {...props}>
        {children}
      </StyledS>
    );
  },
  S5: function S5({ children, ...props }) {
    return (
      <StyledS className="subtitle is-5" {...props}>
        {children}
      </StyledS>
    );
  },
};

export default Title;

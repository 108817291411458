import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import TextareaField from "../../../../../components/Fields/TextareaField";
import HmiField from "../../../../../components/HmiField/HmiField";

function ContainerEmptyStep1({ notes, setNotes, container, onSubmit }) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <HmiField
      title={intl.formatMessage({
        id: "app.pages.application.container_item.notes.title",
        defaultMessage: "Do you want to add a note?",
      })}
      subtitle={container.name}
      subtitle2={
        container.item && `${container.item.code} :: ${container.item.name}`
      }
      field={
        <TextareaField
          key="notes"
          placeholder={notes}
          defaultValue={notes}
          onChange={(value) => {
            setNotes(value);
          }}
        />
      }
      value={notes}
      onBack={() => history.goBack()}
      onSubmit={onSubmit}
    />
  );
}

ContainerEmptyStep1.propTypes = {
  notes: PropTypes.string,
  setNotes: PropTypes.func.isRequired,
  container: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContainerEmptyStep1;

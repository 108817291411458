import React from "react";
import { Switch } from "react-router-dom";
import HmiRoute from "../../../libs/hmi-route";
import AdvancedMenu from "./advanced-menu";
import AdvancedMachineMachine from "./advanced-machine";
import AdvancedTeam from "./advanced-team";
import AdvancedProgram from "./advanced-program";

function Routes() {
  return (
    <Switch>
      <HmiRoute
        path="/configuration/advanced/menu/:jobId/:phaseId"
        component={AdvancedMenu}
      />
      <HmiRoute
        path="/configuration/advanced/machine/:jobId/:phaseId"
        component={AdvancedMachineMachine}
      />
      <HmiRoute
        path="/configuration/advanced/program/:jobId/:phaseId"
        component={AdvancedProgram}
      />
      <HmiRoute
        path="/configuration/advanced/team/:jobId/:phaseId/:index?"
        component={AdvancedTeam}
      />
    </Switch>
  );
}

export default Routes;

import React from "react";
import PropTypes from "prop-types";
import ListElement from "../../../../../components/ListElement";
import themes from "../../../../../libs/themes";
import NumberFormatter from "../../../../../libs/format-number";

function PickingItem({ picking }) {
  const { container, item } = picking;
  return (
    <ListElement
      // onClick={() => onClick(item._id)}
      theme={themes.default.listElement}
      data-cy="application-supply_settings-item"
      data-cy-id={picking._id}
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className="column">
          <ul>
            <li className="title is-4">{item?.code}</li>
            <li className="subtitle is-4 fg-secondary">{item?.name}</li>
          </ul>
        </div>
        <div className="column is-narrow has-text-right">
          <ul>
            <li className="title is-4">
              {container?.name}{" "}
              {container?.batch && <small>({container.batch.code})</small>}
            </li>
            <li className="subtitle is-4 fg-secondary">
              <strong>
                <NumberFormatter value={container?.current} /> {container?.unit}
              </strong>
              {container?.capacity && (
                <>
                  <small>
                    /<NumberFormatter value={container.capacity} />
                  </small>{" "}
                  <small className="fg-bluish-grey">
                    {container.percentage || 0}%
                  </small>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </ListElement>
  );
}

PickingItem.propTypes = {
  picking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    container: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      batch: PropTypes.object.isRequired,
      capacity: PropTypes.number,
      current: PropTypes.number,
      percentage: PropTypes.number,
    }),
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default PickingItem;

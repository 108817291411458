import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Background from "../../../../components/Layout/Background";
import themes from "../../../../libs/themes";
import Header from "../../../../components/Header";

import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Title from "../../../../components/Title";
import Button from "../../../../components/Button";
import Subtitle from "../../../../components/Subtitle";
import PickingList from "./components/PickingList";
import { loader } from "graphql.macro";
const SUPPLY_PICKING = loader("./SupplyPicking.query.graphql");

function SupplyPicking() {
  const { sessionId } = useParams();
  const history = useHistory();
  const variables = { sessionId };
  const { data, loading, error } = useQuery(SUPPLY_PICKING, { variables });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;

  return (
    <>
      <Helmet>
        <title>Supply - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              {/* <div className="column is-4 is-offset-3"> */}
              {/*  <Button */}
              {/*    isFullWidth */}
              {/*    theme={themes.default.buttons} */}
              {/*    disabled */}
              {/*    data-cy="application-supply_picking-check-configuration" */}
              {/*  > */}
              {/*    <FormattedMessage */}
              {/*      id="app.pages.application.supply_picking.configuration.button" */}
              {/*      defaultMessage="Configuration" */}
              {/*    /> */}
              {/*  </Button> */}
              {/* </div> */}
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_picking.title"
                    defaultMessage="Choose component"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.application.supply_picking.subtitle"
                    defaultMessage="Configure batch and container"
                  />
                </Subtitle.S3>
              </div>
            </div>
            <div className="columns has-text-centered">
              <div className="column">
                <PickingList list={session.picking} />
              </div>
              {session.picking.length === 0 && (
                <div className="column">
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.application.supply_picking.empty"
                      defaultMessage="Picking list not configured, please contact a manager"
                    />
                  </Title.H2>
                </div>
              )}
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyPicking.propTypes = {};
export default SupplyPicking;

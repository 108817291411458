import React from "react";
import { useSelector } from "react-redux";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";

import Avatar from "./Avatar";

const Badge = styled.div`
  border-radius: 1.4rem;
  background-color: #d9e0ec;
  padding: 0.4rem 0.8rem 0.4rem 0.7rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  > * {
    display: inline-block;
    vertical-align: sub !important;
  }

  .sb-avatar {
    margin-right: 0.4rem;
  }
  .more {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.05rem 0.5rem;
    vertical-align: middle !important;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0 0.2rem 0 0.4rem;
    transform: translateY(-1px);
    border-radius: 0.9rem;
  }
`;

const BadgeLarge = styled.span`
  display: inline-block;
  margin-right: 0.8rem;
  margin-left: -0.2rem;
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1rem 0.4rem 0.8rem;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  > * {
    display: inline-block;
    vertical-align: sub !important;
  }

  .sb-avatar {
    margin-right: 0.4rem;
    transform: translateY(-0.2rem);
  }

  .more {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0.2rem 1rem;
    vertical-align: middle !important;
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 0.2rem 0 0.8rem;
    transform: translateY(-2px);
    border-radius: 1.2rem;
  }
`;

function SessionBadge({
  children,
  selectable = false,
  selected = false,
  withCircle = true,
  isSmall = true,
  sessions,
  onSelect,
  theme,
  icon,
  ...props
}) {
  const user = useSelector(({ user }) => user);

  const filteredSessions = sessions.filter((s) => {
    return s.worker._id != user.worker._id;
  });
  const content = filteredSessions?.length && (
    <>
      {filteredSessions?.map((s) => (
        <Avatar
          size={isSmall ? "1.8rem" : "2.4rem"}
          color="transparent"
          name={s.worker.name}
          src={s.worker.image}
          key={s.worker._id}
          style={{
            backgroundColor: "#363636",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        />
      ))}
      {filteredSessions[0].worker.name}
      {filteredSessions.length > 1 && (
        <div className="more">+ {filteredSessions.length - 1}</div>
      )}
    </>
  );

  return (
    filteredSessions?.length > 0 && (
      <>
        {isSmall ? (
          <Badge>{content}</Badge>
        ) : (
          <BadgeLarge>{content}</BadgeLarge>
        )}
      </>
    )
  );
}

SessionBadge.propTypes = {};
export default compose(withTheme)(SessionBadge);

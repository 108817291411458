import React from "react";
import { Redirect, useHistory, useParams, useLocation } from "react-router-dom";
import { useQuery, useSubscription } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import NextStepButton from "./components/NextStepButton";
import PreviousStepButton from "./components/PreviousStepButton";
import themes, { statuses } from "../../../libs/themes";
import Background from "../../../components/Layout/Background";
import * as Bodies from "../components";
import SetStepMutation from "./components/SetStep";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import CenteredLayout from "../../../layouts/CenteredLayout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CheckStatus from "../../../components/CheckStatus";
import { loader } from "graphql.macro";
import HasFeature from "../../../components/HasFeature";
const STEP_QUERY = loader("./Step.query.graphql");
const CYCLE_RECEIVED = loader("./Step.subscription.graphql");

function Step() {
  const history = useHistory();
  const location = useLocation();
  const { phaseId, sessionId, stepId } = useParams();
  const user = useSelector(({ user }) => user);

  const variables = { phaseId, workerId: user.worker._id };

  const { data, loading, error } = useQuery(STEP_QUERY, { variables });

  useSubscription(CYCLE_RECEIVED, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase } = data;
  const { STEPS = [] } = phase.configuration;
  if (STEPS.length === 0) {
    throw new Error("Error, steps empty");
  }
  const index = stepId ? STEPS.findIndex(({ _id }) => _id === stepId) || 0 : 0;
  const step = STEPS[index];
  const { name, status } = step;
  const theme = statuses[status];
  // Force redirect to first step
  if (!stepId) {
    return (
      <Redirect
        to={{
          pathname: `/application/step/${phaseId}/${sessionId}/${step._id}`,
          state: { from: location },
        }}
      />
    );
  }

  //if at least one session.picking.container is null, set disable to true
  const nextStepDisabled =
    phase.session.picking.some((p) => p.container === null) &&
    status === "MATERIAL";

  return (
    <CheckStatus>
      <SetStepMutation status={status} timeout={800}>
        <ThemeProvider theme={theme}>
          <Background
            head={<Header hasMenu />}
            foot={
              <div className="columns">
                <div className="column">
                  {index > 0 && <PreviousStepButton steps={STEPS} />}
                </div>
                <div className="column">
                  {status === "SETUP" && phase?.session?.machine && (
                    <HasFeature feature="MACHINE:INTEGRATION">
                      <Button
                        isFullWidth
                        theme={themes.setupYellow.buttons}
                        onClick={() =>
                          history.push(
                            `/application/machine/${phaseId}/${sessionId}/${phase.session._machine}`
                          )
                        }
                        data-cy="application-button-machine"
                      >
                        <FormattedMessage
                          id="app.pages.application.setup.buttons.machine"
                          defaultMessage="Machine"
                        />
                      </Button>
                    </HasFeature>
                  )}
                  {status === "PRODUCTION" &&
                    phase.session.hasSerialnumbers && (
                      <Button
                        isFullWidth
                        theme={themes.productionBlue.buttons}
                        onClick={() =>
                          history.push(
                            `/application/scan-production/${phaseId}/${sessionId}`
                          )
                        }
                        data-cy="application-button-next"
                      >
                        <FormattedMessage
                          id="app.pages.application.production.buttons.production_history"
                          defaultMessage="Scan production"
                        />
                      </Button>
                    )}
                </div>

                <div className="column">
                  <Choose>
                    <When condition={index + 1 === STEPS.length}>
                      <Button
                        isFullWidth
                        theme={themes.primary.buttons}
                        onClick={() =>
                          history.push(
                            `/application/delivery/${phaseId}/${sessionId}/${stepId}`
                          )
                        }
                        data-cy="application-button-next"
                      >
                        <FormattedMessage
                          id="app.shared.delivery"
                          defaultMessage="Delivery"
                        />
                      </Button>
                    </When>
                    <Otherwise>
                      <NextStepButton
                        steps={STEPS}
                        disabled={nextStepDisabled}
                      />
                    </Otherwise>
                  </Choose>
                </div>
              </div>
            }
          >
            <Choose>
              <When condition={status === "APPROVAL"}>
                <Bodies.ApprovalBody phase={phase} stepName={name} />
              </When>
              <When condition={status === "MATERIAL"}>
                <Bodies.MaterialBody phase={phase} stepName={name} />
              </When>
              <When condition={status === "PRODUCTION"}>
                <Bodies.ProductionBody phase={phase} stepName={name} />
              </When>
              <When condition={status === "SETUP"}>
                <Bodies.SetupBody phase={phase} stepName={name} />
              </When>
              <When condition={status === "CHECK"}>
                <Bodies.CheckBody phase={phase} stepName={name} />
              </When>
              <Otherwise>{status} UNKNOWN STATUS</Otherwise>
            </Choose>
          </Background>
        </ThemeProvider>
      </SetStepMutation>
    </CheckStatus>
  );
}

Step.propTypes = {};

export default Step;

import { useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../../components/Button";
import Clock from "../../../../components/Clock";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import BackImage from "../../../../images/arrow-back-gray-icn.svg";
import themes from "../../../../libs/themes";

import { loader } from "graphql.macro";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import toast from "../../../../libs/toast";
const ADVANCED_MENU_QUERY = loader("./AdvancedMenu.query.graphql");

function AdvancedMenu() {
  const intl = useIntl();
  const history = useHistory();
  const { jobId, phaseId } = useParams();
  const { team, machineId, programId } = useSelector(({ config }) => config);
  const theme = themes.default;
  const variables = { programId, machineId, phaseId };

  const { data, loading, error } = useQuery(ADVANCED_MENU_QUERY, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, machine, program } = data;
  function onMachineClick() {
    if (phase._machine) {
      return toast({
        title: intl.formatMessage({
          id: "app.pages.configuration.advanced.warning.machine_locked",
          defaultMessage: "Sorry, you cannot change machine",
        }),
        icon: "warning",
      });
    }
    history.push(`/configuration/advanced/machine/${jobId}/${phaseId}`);
  }
  function onProgramClick() {
    if (phase._machine) {
      return toast({
        title: intl.formatMessage({
          id: "app.pages.configuration.advanced.warning.program_locked",
          defaultMessage: "Sorry, you cannot change program",
        }),
        icon: "warning",
      });
    }
    history.push(`/configuration/advanced/program/${jobId}/${phaseId}`);
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Advanced - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() =>
                    history.push(
                      `/configuration/definition/${jobId}/${phaseId}`
                    )
                  }
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.title"
                    defaultMessage="Advanced options"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.subtitle"
                    defaultMessage="Choose advanced configuration"
                  />
                </Subtitle.S3>
                <div className="columns has-text-centered is-multiline">
                  {/* TEAM */}
                  <div className="column is-half is-offset-3">
                    <Button
                      isFullWidth
                      onClick={() =>
                        history.push(
                          `/configuration/advanced/team/${jobId}/${phaseId}`
                        )
                      }
                      data-cy="configuration-advanced-button-team"
                    >
                      <FormattedMessage
                        id="app.pages.configuration.advanced.team.button"
                        defaultMessage="Team"
                      />

                      {team.length > 0 && (
                        <span
                          className="fg-secondary"
                          style={{ marginLeft: "1rem" }}
                        >
                          <FormattedMessage
                            id="app.pages.configuration.advanced.team.button.members"
                            defaultMessage="{members} {members, plural, one {member} other {members}}"
                            values={{ members: team.length }}
                          />
                        </span>
                      )}
                    </Button>
                  </div>
                  {/* MACHINE */}
                  <div className="column is-half is-offset-3">
                    <Button
                      isFullWidth
                      data-cy="configuration-advanced-button-machine"
                      disabled={phase._machine}
                      onClick={onMachineClick}
                    >
                      <FormattedMessage
                        id="app.pages.configuration.advanced.machine.button"
                        defaultMessage="Machine"
                      />
                      {machine && (
                        <span
                          className="fg-secondary"
                          style={{ marginLeft: "1rem" }}
                        >
                          {machine.name}
                        </span>
                      )}
                    </Button>
                  </div>
                  {/* PROGRAM */}
                  <div className="column is-half is-offset-3">
                    <Button
                      isFullWidth
                      data-cy="configuration-advanced-button-program"
                      disabled={phase._program}
                      onClick={onProgramClick}
                    >
                      <FormattedMessage
                        id="app.pages.configuration.advanced.program.button"
                        defaultMessage="Program"
                      />
                      {program && (
                        <span
                          className="fg-secondary"
                          style={{ marginLeft: "1rem" }}
                        >
                          {program.name}
                        </span>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default AdvancedMenu;

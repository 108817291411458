import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import { setStatus, setError } from "../../../actions";
import themes from "../../../libs/themes";
import Background from "../../../components/Layout";
import Title from "../../../components/Title";
import { SmallTag } from "../../../components/Tag";
import ListElement from "../../../components/ListElement";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import StepHeader from "../../../components/StepHeader";

function ErrorPicker() {
  const intl = useIntl();
  const { phaseId, sessionId, stepId = "" } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  function onClick(message) {
    dispatch(setError(message));
    dispatch(setStatus("ERROR"));
    history.push(`/application/produced/${phaseId}/${sessionId}`);
  }

  function onBack() {
    dispatch(setError());
    history.goBack();
  }

  const theme = themes.errorRed;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Problem Picker - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              {/* /!*FIRST BUTTON*!/ */}
              <div className="column is-1">
                {/* MENU BUTTON */}
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={onBack}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../images/arrow-back-gray-icn.svg").default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-6 is-offset-2">
                <Button
                  isFullWidth
                  theme={themes.errorRed.buttons}
                  onClick={() =>
                    history.push(
                      `/application/error/report/${phaseId}/${sessionId}/${stepId}`
                    )
                  }
                  data-cy="application-issue-button-other"
                >
                  <FormattedMessage
                    id="app.pages.application.error_picker.buttons.problem_report"
                    defaultMessage="Other fault"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.errorRed.buttons}>
                  <FormattedMessage
                    id="app.shared.error"
                    defaultMessage="Fault"
                  />
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.error_picker.title"
                    defaultMessage="Choose fault"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
            </div>
            <div className="columns">
              <div className="column is-half has-text-centered">
                <ListElement
                  theme={theme.listElement}
                  onClick={() =>
                    onClick(
                      intl.formatMessage({
                        id: "app.shared.phase_errors.MATERIAL_NOT_AVAILABLE",
                      })
                    )
                  }
                >
                  <FormattedMessage
                    id="app.shared.phase_errors.MATERIAL_NOT_AVAILABLE"
                    defaultMessage="Material not available"
                  />
                </ListElement>
              </div>
              <div className="column is-half has-text-centered">
                <ListElement
                  theme={theme.listElement}
                  onClick={() =>
                    onClick(
                      intl.formatMessage({
                        id: "app.shared.phase_errors.BLOCKING",
                      })
                    )
                  }
                >
                  <FormattedMessage
                    id="app.shared.phase_errors.BLOCKING"
                    defaultMessage="Blocking error"
                  />
                </ListElement>
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ErrorPicker.propTypes = {};

export default ErrorPicker;

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";

import Button from "../../../../components/Button";

/**
 * Previous Step Button
 *
 * @param steps
 * @param history
 * @param phaseId
 * @param sessionId
 * @param stepId
 * @returns {*}
 * @constructor
 */
function PreviousStepButton({
  steps,
  history,
  match: {
    params: { phaseId, sessionId, stepId },
  },
  intl,
}) {
  const currentIndex = steps.findIndex(({ _id }) => _id === stepId);
  if (currentIndex > 0) {
    const step = steps[currentIndex - 1];
    return (
      <Button
        isFullWidth
        step={step.step}
        onClick={() =>
          history.push(`/application/step/${phaseId}/${sessionId}/${step._id}`)
        }
        data-cy="application-button-back"
      >
        <i className="icon-arrow-left-small-icn" />{" "}
        {step.name ||
          intl.formatMessage({
            id: `app.shared.${step.status.toLowerCase()}`,
            defaultMessage: step.status,
          })}
      </Button>
    );
  }
  return <span />;
}

PreviousStepButton.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string,
      status: PropTypes.string.isRequired,
    })
  ),
};

export default compose(withRouter, injectIntl)(PreviousStepButton);

import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

function BatchProductivity({ percentage, produced, qty }) {
  const intl = useIntl();
  return (
    <div className="single-chart">
      <svg viewBox="0 0 36 36" className="circular-chart batch">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {percentage > 0 && (
          <path
            className="circle"
            strokeDasharray={`${percentage}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        )}
        <text x="18" y="15.2" className="circle-title">
          {intl.formatMessage({ id: "app.shared.batch" })}
        </text>
        <text
          x="18"
          y="20"
          className="circle-value"
          data-cy="application-session-batch-produced"
        >
          {intl.formatNumber(produced)}
        </text>
        <line
          x1="10"
          x2="26"
          y1="21.5"
          y2="21.5"
          style={{ stroke: "#cfd8dc", strokeWidth: ".02rem" }}
        />
        <text x="18" y="25" className="circle-subtitle">
          {intl.formatMessage({ id: "app.shared.set" })}
        </text>
        <text x="18" y="28" className="circle-subvalue">
          <Choose>
            <When condition={qty}>{intl.formatNumber(qty)}</When>
            <Otherwise>{intl.formatMessage({ id: "app.shared.na" })}</Otherwise>
          </Choose>
        </text>
      </svg>
    </div>
  );
}

BatchProductivity.propTypes = {
  percentage: PropTypes.number,
  produced: PropTypes.number,
  qty: PropTypes.number,
};
export default BatchProductivity;

import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { loader } from "graphql.macro";

import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ProgressBar from "../../../../components/ProgressBar";
import Subtitle from "../../../../components/Subtitle";
import TableHeader from "../../../../components/TableHeader";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import NumberFormatter from "../../../../libs/format-number";
import themes from "../../../../libs/themes";
import { setContainerAction, setContainerQty } from "../../../../actions";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";
import SortableItem from "./components/SortableItem";

const SUPPLY_ITEM = loader("./SupplyItem.query.graphql");
const CANCEL_WITHDRAW = loader("./CancelWithdraw.mutation.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

const Counter = styled.div`
  font-size: 3.4rem;
  font-weight: 600;
  padding: 3.6rem 2.4rem 2rem 2.4rem;
  line-height: 2.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.has-right-border {
    border-right: 0.02rem solid rgba(0, 0, 0, 0.12);
  }
  span {
    font-size: 2.2rem;
    font-weight: 400;
  }
  p {
    margin-top: 0.8rem;
  }
`;

function SupplyItem() {
  const [items] = useState();
  const dispatch = useDispatch();
  const { phaseId, sessionId, pickingId, stepId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId, pickingId };
  const { data, loading, error, refetch } = useQuery(SUPPLY_ITEM, {
    variables,
  });
  const [mutateCancelWithdraw] = useMutation(CANCEL_WITHDRAW, {
    variables: { sessionId, pickingId },
  });

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { SHOW_LINK_CONTAINER } = hmiConfiguration;

  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  const { pickingItem } = session;
  const { containers } = pickingItem;

  const remaining = phase.qty - phase.produced;

  async function onDelete(_containerId) {
    try {
      const variables = {
        containerId: _containerId,
      };
      await mutateCancelWithdraw({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      refetch();
    } catch (error) {
      onError(error);
    }
  }

  const requestedQuantity =
    pickingItem.qty * remaining > 0 ? pickingItem.qty * remaining : 0;

  const pickedQuantity = containers.reduce((sum, cont) => {
    return (cont.current ?? cont.remaining) + sum;
  }, 0);

  const remainingQuantity = requestedQuantity - pickedQuantity;

  return (
    <>
      <Helmet>
        <title>Supply Item - Agile Factory</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-3">
                <Button
                  theme={themes.materialViolet.buttons}
                  isFullWidth
                  onClick={() => {
                    dispatch(setContainerAction("WITHDRAW"));
                    dispatch(setContainerQty(remainingQuantity));
                    history.push(
                      `/application/supply-item-container/${phaseId}/${sessionId}/${pickingId}/${stepId}`
                    );
                  }}
                  data-cy="application-supply_item-withdraw-button"
                >
                  <p className="title is-3 fg-white">
                    <FormattedMessage
                      id="app.pages.application.supply_item-withdraw"
                      defaultMessage="Withdraw"
                    />
                  </p>
                </Button>
              </div>
              <div className="column is-4">
                {SHOW_LINK_CONTAINER && (
                  <Button
                    theme={themes.primary.buttons}
                    isFullWidth
                    onClick={() => {
                      dispatch(setContainerAction("LINK"));
                      dispatch(setContainerQty(remainingQuantity));
                      history.push(
                        `/application/supply-item-container/${phaseId}/${sessionId}/${pickingId}/${stepId}`
                      );
                    }}
                    data-cy="application-supply_item-container-button"
                  >
                    <p className="title is-3 fg-white">
                      <FormattedMessage
                        id="app.pages.application.supply_item-link-container"
                        defaultMessage="Link container"
                      />
                    </p>
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <>
            <div className="columns is-fullwidth is-vcentered">
              <div className="column">
                <SmallTag
                  isUpperCase
                  isLight
                  theme={themes.materialViolet.buttons}
                >
                  <FormattedMessage
                    id="app.shared.material"
                    defaultMessage="Material"
                  />
                </SmallTag>
                <Title.H1 style={{ marginTop: "1rem" }}>
                  {pickingItem.item.image && (
                    <img
                      src={pickingItem.item.image}
                      alt=""
                      width={44}
                      height={44}
                      className="product-image"
                    />
                  )}
                  {pickingItem.item.name}
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}></Subtitle.S3>
                <Subtitle.S4 theme={themes.secondary}>
                  {pickingItem.item && (
                    <Badge>
                      <i className="icon-product" /> {pickingItem.item.code}
                    </Badge>
                  )}

                  {/* JOB */}
                  <Badge>
                    <i className="icon-sb-job-board-icn" />
                    {phase.job.name}
                  </Badge>
                  {phase.station != null && (
                    <Badge>
                      <i className="icon-st-stations-icn" />
                      {phase.station.name}
                    </Badge>
                  )}
                </Subtitle.S4>
              </div>
              <div
                className="column is-5"
                style={{
                  padding: "0 0.8rem 0 0.8rem",
                  //backgroundColor: "whitesmoke",
                  border: "0.02rem solid #dadada",
                  borderRadius: "1.2rem",
                  overflow: "hidden",
                  transform: "translateX(0.6rem)",
                  maxWidth: "540px",
                }}
              >
                <div className="columns is-marginless-left">
                  <Counter className="column is-half has-text-centerd has-right-border has-20-padding-top">
                    <div>
                      {pickingItem && (
                        <>
                          <NumberFormatter value={pickingItem.qty} />
                          &nbsp;<span>{pickingItem.item.unit}</span>
                        </>
                      )}
                    </div>
                    <p className="title is-6">
                      <FormattedMessage
                        id="app.pages.application.material.table.qty"
                        defaultMessage="Qty"
                      />
                    </p>
                  </Counter>
                  <Counter className="column is-half has-text-centerd has-20-padding-top">
                    <div>
                      {pickingItem && (
                        <>
                          <NumberFormatter value={requestedQuantity} />
                          &nbsp;<span>{pickingItem.item.unit}</span>
                        </>
                      )}
                    </div>
                    <p className="title is-6">
                      <FormattedMessage
                        id="app.pages.application.material.table.tot_qty"
                        defaultMessage="Qty total"
                      />
                    </p>
                  </Counter>
                </div>

                <div
                  className="columns is-vcentered"
                  style={{
                    padding: "0 1.8rem 1.2rem 1.8rem",
                    background: "#fafafa",
                    borderTop: "0.02rem solid #ddd",
                  }}
                >
                  <div
                    className="column"
                    style={{ transform: "translateY(-0.42rem)" }}
                  >
                    <ProgressBar
                      title="Unità prodotte"
                      completed={pickedQuantity ?? 0}
                      total={requestedQuantity}
                      hasTitle={false}
                      theme={themes.productionBlue}
                    />
                  </div>
                  <div className="column is-narrow">
                    <span className="title is-3">
                      <NumberFormatter value={pickedQuantity} />
                    </span>
                    <span className="subtitle is-4">
                      {" "}
                      / <NumberFormatter value={requestedQuantity} />{" "}
                    </span>
                    {pickingItem.item.unit}
                  </div>
                </div>
              </div>
            </div>
            <TableHeader isSmall={true} style={{ marginTop: "1.2rem" }}>
              <div className="column">
                <p className="title is-6">
                  <FormattedMessage
                    id="app.pages.application.material.table.containers_material"
                    defaultMessage="Materiale caricato"
                  />
                </p>
              </div>
              <div className="column is-3">
                <p className="title is-6">
                  <FormattedMessage
                    id="app.pages.application.material.table.withdrawn-qty"
                    defaultMessage="Qta"
                  />
                </p>
              </div>
              <div className="column is-2">
                <p className="title is-6">
                  <FormattedMessage
                    id="app.pages.application.material.table.withdrawn-qty"
                    defaultMessage="Actions"
                  />
                </p>
              </div>
            </TableHeader>
            <section style={{ paddingBottom: "8rem" }}>
              {(items ?? containers).length == 0 && (
                <div className="column has-text-centered has-20-padding-top">
                  <h1 className="title is-4 w700">
                    Nessun materiale prelevato
                  </h1>
                </div>
              )}
              {(items ?? containers).map((item, index) => (
                <>
                  <SortableItem
                    key={item._id}
                    id={item._id}
                    index={index + 1}
                    container={item}
                    pickingItem={pickingItem}
                    onDelete={onDelete}
                    onClick={() =>
                      item &&
                      !item?.hasSerialnumbers &&
                      history.push(
                        `/application/container-edit/${item?._id}/${sessionId}`
                      )
                    }
                    selected={true}
                  />
                </>
              ))}
            </section>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplyItem.propTypes = {};
export default SupplyItem;

import React from "react";
import { useIntl } from "react-intl";
import * as images from "./images";

function getTitle(type) {
  switch (type) {
    case "normal":
      return {
        id: "app.components.container_movement_type.LOAD",
        defaultMessage: "Material loaded to container",
      };
    case "input":
      return {
        id: "app.components.container_movement_type.LOAD",
        defaultMessage: "Material loaded to container",
      };
    case "output":
      return {
        id: "app.components.container_movement_type.UNLOAD",
        defaultMessage: "Material unloaded from container",
      };
    case "scrap":
      return {
        id: "app.components.container_movement_type.EMPTY",
        defaultMessage: "Container emptied",
      };

    default:
      return {
        id: "app.components.container_movement_type.UNKNOWN",
        defaultMessage: "Unknown type, please contact support",
      };
  }
}

function ContainerMovementType({ type, size, ...props }) {
  const intl = useIntl();
  const title = intl.formatMessage(getTitle(type));

  return type == "scrap" ? (
    <i className="icon icon-giphy-delete" />
  ) : (
    <img
      className="image"
      src={images[type] || images.UNKNOWN}
      alt=""
      title={title}
      width={size}
      height={size}
      {...props}
    />
  );
}

export default ContainerMovementType;

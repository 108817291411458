import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "../../../../components/Button";

import styled from "styled-components";
import Badge from "../../../../components/Badge";
import CircleElem from "../../../../components/CircleElem";
import ListElement from "../../../../components/ListElement";
import themes, { statuses } from "../../../../libs/themes";
import SessionBadge from "../../../../components/SessionBadge";

const LineButton = styled(Button)`
  height: 6.8rem;
  font-size: 3rem;
`;

const RemoveButton = styled(LineButton)`
  background-color: #ddd;
  &:hover {
    background-color: #ccc;
  }
`;

function Operation({
  phase,
  onClick,
  selectable = false,
  selected = false,
  onSelect,
  onRelease,
  onTake,
  isTaken = false,
}) {
  let session = phase.activeSession ?? phase.session;

  return (
    <ListElement
      selectable={selectable}
      selected={selected}
      onClick={() => onClick(phase)}
      onSelect={() => onSelect(phase._id)}
      theme={themes.default.listElement}
      data-cy="configuration-operations-operation"
      data-cy-id={phase._id}
      icon={
        phase.product?.image ? phase.product?.image : phase.job?.product?.image
      }
    >
      <div className="columns is-vcentered is-fullwidth is-marginless">
        <div className={"column"}>
          <p className="subtitle is-6">
            <CircleElem
              theme={
                statuses[session?.status]?.listElement ??
                themes.default.listElement
              }
            >
              <FormattedMessage
                id={"app.components.phase.status." + (session?.status ?? "NEW")}
                defaultMessage=""
              />
            </CircleElem>
            {session && (
              <SessionBadge sessions={phase.activeSessions}></SessionBadge>
            )}
            {phase.job && (
              <Badge>
                <i className="icon-sb-job-board-icn" />
                {phase.job.name}
              </Badge>
            )}
            {phase.job?.customer && (
              <Badge>
                <i className="icon-st-users-icn" />
                {phase.job.customer.name}
              </Badge>
            )}
            {phase.station && (
              <Badge>
                <i className="icon-st-areas-icn" />
                {phase.station.name}
              </Badge>
            )}
            <Badge>
              <i className="icon-product" />
              {phase.product
                ? phase.product.code
                : phase.job.product && phase.job.product.code}
            </Badge>
            {phase.job?.salesOrderId && (
              <Badge>
                <i className="icon-doc-text" />
                {phase.job.salesOrderId}
              </Badge>
            )}
          </p>
          <p
            className="is-3"
            style={{ marginTop: "-1.25rem", lineHeight: "1.125" }}
          >
            {phase.sort} :: {phase.name}
          </p>
        </div>
        {!isTaken && (
          <div
            className="column is-narrow is-paddingless"
            style={{ textAlign: "right" }}
          >
            <LineButton
              className="button"
              theme={themes.primary.buttons}
              style={{ transform: "scale(0.8) " }}
              onClick={(e) => {
                e.stopPropagation();
                onTake();
              }}
            >
              <FormattedMessage
                id="app.pages.configuration.operations.add"
                defaultMessage="Add"
              />
            </LineButton>
          </div>
        )}
        {isTaken && phase.session == null ? (
          <div className="column is-narrow" style={{ textAlign: "right" }}>
            <LineButton className="button" theme={themes.primary.buttons}>
              <FormattedMessage
                id="app.pages.configuration.operations.start"
                defaultMessage="Start"
              />
            </LineButton>
          </div>
        ) : (
          <></>
        )}
        {isTaken && !phase.activeSession ? (
          <div className="column is-narrow" style={{ textAlign: "right" }}>
            <RemoveButton
              className="button"
              onClick={(e) => {
                e.stopPropagation();
                onRelease({ phaseId: phase._id });
              }}
              theme={themes.secondary.buttons}
            >
              <FormattedMessage
                id="app.pages.configuration.operations.remove"
                defaultMessage="Remove"
              />
            </RemoveButton>
          </div>
        ) : (
          <></>
        )}
      </div>
    </ListElement>
  );
}

Operation.propTypes = {
  onClick: PropTypes.func.isRequired,
  phase: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    _program: PropTypes.string,
    _machine: PropTypes.string,
    sort: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    produced: PropTypes.number,
    percentage: PropTypes.number,
    qty: PropTypes.number,
    product: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  }).isRequired,
};
export default Operation;

import React from "react";
import { FormattedNumber } from "react-intl";

function NumberFormatter({ value }) {
  if (isNaN(value)) {
    return <span>-</span>;
  }
  return (
    <FormattedNumber
      minimumFractionDigits={value % 1 === 0 ? 0 : 2}
      maximumFractionDigits={value % 1 === 0 ? 0 : 2}
      value={value || 0}
    />
  );
}

function RoundValue(value, decimals = 3) {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export default NumberFormatter;
export { RoundValue };

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledFullHeightContainer = styled.div`
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 4rem);
  border-radius: 6px;
  color: ${({ theme }) => theme.fg};
  background-color: ${({ theme }) => theme.bg};
  //padding: 1rem;
`;

function FullHeightContainer({ children, ...props }) {
  return (
    <StyledFullHeightContainer className="is-fullheight" {...props}>
      {children}
    </StyledFullHeightContainer>
  );
}

FullHeightContainer.propTypes = {
  children: PropTypes.element,
};
export default FullHeightContainer;

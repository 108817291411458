import React from "react";
import { compose } from "redux";
import styled, { ThemeProvider, withTheme } from "styled-components";

const ProgressContainer = styled.div`
  background-color: #cfd8dc66;
  border-radius: 0.6rem;
  height: 1.2rem;
  .progress {
    background-color: ${({ theme }) => theme.border || "#fff"};
    height: 1.2rem;
    margin-top: 1.6rem;
    max-width: 100%;
    min-width: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
  align-items: center;
  margin-right: 1.8rem;
`;

function ProgressBar({
  children,
  total,
  completed,
  theme,
  title,
  hasTitle = true,
  ...props
}) {
  const percentage =
    completed === 0 && total === 0 ? 0 : (completed / total) * 100;
  return (
    <>
      <ThemeProvider theme={theme}>
        {hasTitle && (
          <Row>
            <span>{title}</span>
            <span>
              {completed} su {total}
            </span>
          </Row>
        )}
        <ProgressContainer theme={theme}>
          <div className="progress" style={{ width: percentage + "%" }}></div>
        </ProgressContainer>
      </ThemeProvider>
    </>
  );
}

ProgressBar.propTypes = {};
export default compose(withTheme)(ProgressBar);

import React, { useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import Context from "./Modal.Context";

const ModalContainer = styled.div`
  .modal-container {
    height: calc(100vh - 4rem);
    margin-top: 2rem;
    margin-bottom: 2rem;
    @keyframes modal-show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .modal-background {
      animation: modal-show 1s ease;
      animation-delay: 500ms;
    }

    .modal-back {
      animation: modal-show 1s ease;
      //opacity: 0;
      //animation: modal-show 1s ease;
      //animation-delay: 500ms;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      cursor: pointer;
      .content {
        width: 100%;
        height: 100%;
        position: relative;
        .back-text {
          position: absolute;
          bottom: 2rem;
          width: 100%;
        }
      }
    }
    .modal-main {
      animation: modal-show 0.5s ease;
      padding-right: 4.8rem;
      width: 100vw;
      .head {
        align-items: flex-start;
        flex-grow: 0;
        background-color: transparent;
      }
      .body {
        padding: 2.5rem;
        margin-top: -2.4rem;
        margin-bottom: -3.2rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

/**
 * Component that shows an image, when clicked shows a modal
 */
function Modal({ children, head, button, active }) {
  const [isOpen, setIsOpen] = useState(active);
  return (
    <Context.Provider value={{ isOpen, setIsOpen }}>
      <div className="column is-4">
        <figure className="doc-image">
          <a onClick={() => setIsOpen(!isOpen)}>{button}</a>
        </figure>
        <ModalContainer className={classNames("modal", isOpen && "is-active")}>
          <div
            className="modal-background"
            onClick={() => setIsOpen(!isOpen)}
          />
          <div className="tile is-ancestor modal-container">
            <div className="tile is-parent is-2 modal-back">
              <article
                className="tile is-child notification is-danger is-fullheight"
                onClick={() => setIsOpen(false)}
              >
                <div className="content">
                  <p className="title is-4 has-text-centered back-text">
                    <FormattedMessage
                      id="app.shared.back_lowercase"
                      defaultMessage="back"
                    />
                  </p>
                </div>
              </article>
            </div>
            <div className="tile is-parent is-vertical modal-main">
              <article className="tile is-child notification head">
                <div className="content">{head}</div>
              </article>
              <article className="tile notification body">
                <div className="content is-fullwidth">{children}</div>
              </article>
            </div>
          </div>
        </ModalContainer>
      </div>
    </Context.Provider>
  );
}

Modal.propTypes = {
  head: PropTypes.node,
  button: PropTypes.node,
  children: PropTypes.node,
  active: PropTypes.bool,
};
export default Modal;
